import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { markAsmActiveApiCall} from "./ApiCalls";
import { StyledEngineProvider } from "@mui/material/styles";
import DatePickerRejoin from "./SendOlModal/DatePickerSoi/DatePickerRejoin";
import { TextField } from "@mui/material";
import {CmContext} from "./../../App";
import Swal from "sweetalert2";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function ActiveCommentPP({ open, setOpen }) {
  //   const handleOpen = () => setOpen(true);
  const [context,setContext]=React.useContext(CmContext);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
            Date of Rejoining
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="soi-form">
              <StyledEngineProvider injectFirst>
                <DatePickerRejoin />

                <TextField
                  min={0}
                  id="outlined-number"
                  label="Employee Id"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    console.log(e.target.value, "empId selected");
                    context.updateOnboardStatusData.emp_id = e.target.value;
                    setContext({ ...context });
                  }}
                />
              </StyledEngineProvider>
            </div>
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2 }}>
            Enter Comment
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <textarea
              style={{ width: "100%",border:"1px solid lightgrey"}}
              onChange={(e) => {
                console.log(e.target.value);
                context.updateOnboardStatusData.comment=e.target.value;
                setContext({...context});
              }}
            ></textarea>
          </Typography>
          <button
          className="float-end mt-3"
            onClick={() => {
              if(context.updateOnboardStatusData.comment.length<1){
                handleClose();
                Swal.fire({
                  icon:"warning",
                  title:"Empty Comment"
                })
              }else{
                handleClose();
                console.log("ACTIVE comment popup" , context.updateOnboardStatusData)
                markAsmActiveApiCall(
                  context.updateOnboardStatusData.fse_user_id,
                  context.updateOnboardStatusData.hr_admin_id,
                  context.updateOnboardStatusData.comment,
                  context.updateOnboardStatusData.action_type,
                  context.updateOnboardStatusData.dojDate,
                  context.updateOnboardStatusData.emp_id 
                );
                context.updateOnboardStatusData.comment="";
                context.updateOnboardStatusData.emp_id ="";
                context.updateOnboardStatusData.dojDate="";
                setContext({...context});
              }
            }}
          >
            Save
          </button>
        </Box>
      </Modal>
    </div>
  );
}
