import React, { useEffect, useState, useContext } from "react";
import "./FseList.css";
import { makeStyles } from "@material-ui/styles";
import { Link, useHistory, useParams } from "react-router-dom";

import Chart from "react-apexcharts";
import { Modal } from "@material-ui/core";
import { getAsmList, updateOnboardStatus } from "./ApiCalls";
import Calender2 from "../calender2/Calender2";
// import CalenderAttend from "../calender/Calender";
import { apexMultiline, apexBarFse, apexMultiline2 } from "../../ApexOptions";
import {
  ASM_STATS_URL,
  checkInvalid,
  FSE_CHART_URL,
  FSE_LIST_URL,
  FSE_WISE_CHECKINS,
  getXleads,
} from "../../utils";
// import fseDummyData from "./fseDummyData"; //uncomment for local dummy data;
import { CmContext } from "../../App";
import Swal from "sweetalert2";
import "@natscale/react-calendar/dist/main.css";
import Calender3 from "../Calender3/Calender3";
import { formatAMPM } from "../../utils";
import InactiveCommentPP from "./InactiveCommentPP";
import ActiveCommentPP from "./activeCommentPP";
import axios from "axios";
import AsmListToRemapFse from "./AsmListToRemapFse";
const useStyles = makeStyles({
  modalStyle1: {
    position: "absolute",
    top: "10%",
    left: "10%",
    overflow: "scroll",
    height: "100%",
    display: "block",
  },
});

function FseList({ setLoading }) {
  const [context, setContext] = useContext(CmContext);
  const [openAsmList, setOpenAsmList] = useState(false);
  const [openInactiveComment, setOpenInactiveComment] = useState(false);
  const [openActiveComment, setOpenActiveComment] = useState(false);
  const [activeFse, setActiveFse] = useState(true);
  const [inactiveFse, setInactiveFse] = useState(false);
  const [fseCheckedIn, setFseCheckedIn] = useState(false);
  const [apexBarFseState, setApexBarFseState] = useState({ ...apexBarFse });
  const [noChartInfo, setNoChartInfo] = useState(false);
  const [chartType, setChartType] = useState("line");
  const [renderChart, setRenderChart] = useState(true);
  const [fseDummyData, setFseDummyData] = useState([]);
  const [asmStatsApi, setAsmStatsApi] = useState({});
  const { asmId } = useParams();
  const classes = useStyles();
  const [active, setActive] = useState([]);
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const handleClick = (index) => {
    active[index] = !active[index];
    setActive([...active]);
  };

  const handlePop = () => {
    setOpen(true);
    setActive(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const setChartData = () => {
    setTimeout(() => {
      const series1 = [10, 20, 30, 40, 50];
      const series2 = [5, 10, 15, 20, 25];
      const series3 = [3, 5, 7, 10, 13];
      const labels = ["1", "2", "3", "4", "5", "1", "2", "3", "4", "5"];
      apexBarFseState.series[0].data = series1;
      apexBarFseState.series[1].data = series2;
      apexBarFseState.series[2].data = series3;
      apexBarFseState.options.xaxis.categories = labels;
      setApexBarFseState({ ...apexBarFseState });
      setRenderChart(false);
      setRenderChart(true);
    }, 1000);
  };
  const number1 = [];

  for (var i = 1; i <= 31; i++) {
    number1.push(i);
  }

  const fetchCallFseChart = (fse_id) => {
    console.log(fse_id, "fetch call fse chart");
    fetch(FSE_CHART_URL + "?fse_id=" + fse_id)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error("Fetch request failed");
        }
      })
      .then((res) => {
        console.log(res, "fetch call fse chart");
        if (res.error && res.message === "DATA_EMPTY") {
          setNoChartInfo(true);
          apexBarFseState.series[0].data = [];
          apexBarFseState.series[1].data = [];
          apexBarFseState.series[2].data = [];
          apexBarFseState.options.xaxis.categories = [];

          setApexBarFseState({ ...apexBarFseState });
          setRenderChart(false);
          setRenderChart(true);
        }
        context.fseChartApi = res.data;
        setContext({ ...context });
        if (res.data) {
          const series1 = res.data.map(({ count: leads }) => {
            return leads;
          });
          const series2 = res.data.map(({ approved }) => {
            return approved;
          });
          const series3 = res.data.map(({ approved }) => {
            return getXleads(approved);
          });
          const label = res.data.map(({ day }) => {
            return "Day-" + day;
          });
          if (label.length < 3) {
            setChartType("bar");
          } else {
            setChartType("line");
          }

          apexBarFseState.series[0].data = series1;
          apexBarFseState.series[1].data = series2;
          apexBarFseState.series[2].data = series3;
          apexBarFseState.options.xaxis.categories = label;
          setApexBarFseState({ ...apexBarFseState });

          setRenderChart(false);
          setRenderChart(true);
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title:
            "error: please reload or try again after some time, errorObj: " +
            error,
        });
      });
  };

  const fetchCallFseCheckins = (asm_id) => {
    console.log(asm_id, "fetch call fse checkins for this asm");
    fetch(FSE_WISE_CHECKINS + "?asm_id=" + asm_id)
      .then((res) => {
        console.log(res);
        if (res.ok) {
          return res.json();
        } else {
          throw Error("Fetch request failed for fse wise checkins api");
        }
      })
      .then((res) => {
        console.log(res, "fetch call fse wise checkins");
        if (res.error && res.message === "DATA_EMPTY") {
          Swal.fire({
            icon: "warning",
            title: "No checkins under this asm",
          });
        }
        context.fseWiseCurMonthCheckinsApi = res.data;
        setContext({ ...context });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title:
            "error: please reload or try again after some time, errorObj: " +
            error,
        });
      });
  };

  const handleFseRemap = (fse_id, asm_id) => {};

  useEffect(() => {
    const fseList = localStorage.getItem("fseList");
    if (checkInvalid(fseList)) {
      localStorage.setItem("fseList", JSON.stringify([]));
    } else if (
      JSON.parse(fseList).length >= localStorage.getItem("asm_id") &&
      JSON.parse(fseList)[Number(localStorage.getItem("asm_id"))]
    ) {
      const data = JSON.parse(fseList)[localStorage.getItem("asm_id")];
      setAsmStatsApi({ ...data.stats });
    }
    const asyncFunc = async () => {
      const res1 = await fetch(ASM_STATS_URL, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          asm_id: localStorage.getItem("asm_id"),
        }),
      });
      if (res1.ok) {
        const res = await res1.json();
        console.log(res, "asmStats response");
        if (res.error) {
        } else {
          setAsmStatsApi({ ...res });
          const asmId = localStorage.getItem("asm_id");
          const myfseList = JSON.parse(localStorage.getItem("fseList"));
          if (!checkInvalid(myfseList[asmId])) {
            myfseList[asmId].stats = { ...res };
          } else {
            myfseList[asmId] = {
              stats: { ...res },
            };
          }

          localStorage.setItem("fseList", JSON.stringify([...myfseList]));
        }
      } else {
        Swal.fire({
          icon: "error",
          text: "Unable to Fetch",
        });
      }
    };
    asyncFunc();
    fetchCallFseCheckins(localStorage.getItem("asm_id"));
  }, []);

  useEffect(() => {
    const fseList = localStorage.getItem("fseList");
    if (checkInvalid(fseList)) {
      localStorage.setItem("fseList", JSON.stringify([]));
    } else if (
      JSON.parse(fseList).length >= localStorage.getItem("asm_id") &&
      JSON.parse(fseList)[Number(localStorage.getItem("asm_id"))]
    ) {
      const data = JSON.parse(fseList)[localStorage.getItem("asm_id")];
      // setAsmStatsApi({ ...data.stats });
      console.log(data.details, "data.details in fselist");
      if (!checkInvalid(data.details)) {
        setFseDummyData([...data.details]);
        data.details.forEach(() => {
          active.push(false);
        });
      }
    }
    console.log(asmId, "asmIDfromparm");
    setLoading(true);
    const asyncFunc = async () => {
      const reqOptions = {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          asm_id: localStorage.getItem("asm_id"),
        }),
      };
      const res1 = await fetch(
        FSE_LIST_URL + `?asm_id=${localStorage.getItem("asm_id")}`
      );
      if (res1.ok) {
        setLoading(false);
        const res = await res1.json();
        if (res.error) {
        } else {
          console.log(res, "FSE LIST");
          context.fseListApi = res;
          setContext({ ...context });
          const arrangeArr = res.data.map((obj) => {
            return {
              fse_id: obj.user_id,
              is_active: "1",
              tl_name: obj.full_name,
              team_size: "45",
              doj: obj.member_since,
              location: "obj.city",
              // checkin_thismonth:obj.total_checkin,
              // checkin_today:obj.total_checkin_today,
              total_leads: obj.total_leads,
              total_approved: obj.total_approved,
              leads_thismonth: obj.total_leads_thismonth,
              leads_today: obj.total_leads_today,
              approved_thismonth: obj.total_approved_thismonth,
              approved_today: obj.total_approved_today,
              onboarded_by: obj.onboarded_by,
              total_star: obj.total_star,
              // onboarded_on:obj.onboarded_on.substr(0,10),
              onboard_status: obj.onboard_status,
              inactivated_by: "",
              profile_image: obj.profile_image,
              mobile_number: obj.mobile_number,
            };
          });
          setFseDummyData([...arrangeArr]);
          arrangeArr.forEach(() => {
            active.push(false);
          });
          // set fseDetails in localStorage
          const asmId = localStorage.getItem("asm_id");
          const myfseList = JSON.parse(localStorage.getItem("fseList"));
          if (!checkInvalid(myfseList[asmId])) {
            myfseList[asmId].details = [...arrangeArr];
          } else {
            myfseList[asmId] = {
              details: [...arrangeArr],
            };
          }
          localStorage.setItem("fseList", JSON.stringify([...myfseList]));
        }
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          text: "Unable to Fetch",
        });
      }
    };
    asyncFunc();

    // fseDummyData.forEach(()=>{
    //   active.push(false);
    // })
  }, []);
  console.log(number1, "something");
  return (
    <div className="tl_list">
      <InactiveCommentPP
        open={openInactiveComment}
        setOpen={setOpenInactiveComment}
      />
      <ActiveCommentPP
        open={openActiveComment}
        setOpen={setOpenActiveComment}
      />
      <AsmListToRemapFse open={openAsmList} setOpen={setOpenAsmList} />
      <div className="dashtl_header">
        <div className="tl_list_row1">
          {/* <Link to="/dash/asmList">
            <img
              src={window.location.origin + "/images/svg/back_button.svg"}
              alt="profile"
              className="back_butt"
            />
          </Link> */}
          {/* <div className="track_tl">
            <div className="training_assign">
              <img
                src={
                  window.location.origin + "/images/svg/training_assigned.svg"
                }
                alt="profile"
                className="complete_track"
              />
              <p className="training_p1">Training Assigned</p>
            </div>

            <div className="dash_tl_line"></div>
            <div className="training_assign">
              <img
                src={
                  window.location.origin + "/images/svg/training completed.svg"
                }
                alt="profile"
                className="complete_track"
              />
              <p className="training_p1" style={{ color: "#777777" }}>
                Training Completed
              </p>
            </div>

            <div className="dash_tl_line1"></div>
            <div className="training_assign">
              <img
                src={
                  window.location.origin + "/images/svg/training completed.svg"
                }
                alt="profile"
                className="complete_track"
              />
              <p className="training_p1" style={{ color: "#777777" }}>
                Payment
              </p>
            </div>
          </div> */}
        </div>

        <div className="tl_list_row1">
          {/* <div className="dashtl_h_col1">
            <img
              src={window.location.origin + "/images/png/profile1.png"}
              alt="profile"
              className="profile_tl"
            />
            <h3 className="tl_list_h3">anjana</h3>
            <p className="dashtl_h_p5">TL</p>
          </div> */}
          <div className="dashtl_h_col2">
            <img
              src={`${
                Object.keys(asmStatsApi).length > 2
                  ? asmStatsApi.profile_image
                  : "XXXX"
              }`}
              alt="profile"
              className="profile_tl"
              onError={(e) => {
                e.target.src =
                  window.location.origin + "/images/profile_default_new.svg";
              }}
            />
            <div className="dashtl_h_row1"></div>
            <div className="dashtl_h_row2" style={{ background: "#394CC6" }}>
              <p
                className="dashtl_h_p1"
                style={{
                  color: "white",
                }}
              >
                {Object.keys(asmStatsApi).length > 2
                  ? asmStatsApi.asm_name
                  : "XXXX"}
              </p>
              <p className="dashtl_h_p5">ASM</p>
              <p
                className="dashtl_h_p2"
                style={{
                  color: "black",
                }}
              >
                {Object.keys(asmStatsApi).length > 2
                  ? asmStatsApi.asm_location
                  : "XXXX"}
              </p>
            </div>
          </div>
          <div className="dashtl_h_col3">
            <div className="dashtl_h_cards fseStatsCard">
              <p className="dashtl_h_p3">Checkin</p>
              <p className="dashtl_h_p4 ">
                {Object.keys(asmStatsApi).length > 2
                  ? asmStatsApi.total_checkin
                  : "XXXX"}
              </p>
              <div className="fseStatsToday">
                +
                {Object.keys(asmStatsApi).length > 2
                  ? asmStatsApi.total_checkin_today
                  : "XXXX"}
              </div>
            </div>
            <div className="dashtl_h_cards fseStatsCard">
              <p className="dashtl_h_p3">Leads</p>
              <p className="dashtl_h_p4">
                {Object.keys(asmStatsApi).length > 2
                  ? asmStatsApi.total_leads
                  : "XXXX"}
              </p>
              <div className="fseStatsToday">
                +
                {Object.keys(asmStatsApi).length > 2
                  ? asmStatsApi.total_leads_today
                  : "XXXX"}
              </div>
            </div>

            <div className="dashtl_h_cards fseStatsCard">
              <p className="dashtl_h_p3">Approved</p>
              <p className="dashtl_h_p4">
                {Object.keys(asmStatsApi).length > 2
                  ? asmStatsApi.total_approved
                  : "XXXX"}
              </p>
              <div className="fseStatsToday">
                +
                {Object.keys(asmStatsApi).length > 2
                  ? asmStatsApi.total_approved_today
                  : "XXXX"}
              </div>
            </div>
            <div className="dashtl_h_cards fseStatsCard">
              <p className="dashtl_h_p3">Active FSE's</p>
              <p className="dashtl_h_p4">
                {Object.keys(asmStatsApi).length > 2
                  ? asmStatsApi.total_fse
                  : "XXXX"}
              </p>
              {/* <div style={{ color: "black", marginTop: "-15px" }}>ASM:FSE</div> */}
            </div>
          </div>
          {/* <div className="dashtl_h_col4">
            <img
              src={window.location.origin + "/images/svg/message.svg"}
              alt="profile"
              className="icon_svg"
            />
            <img
              src={window.location.origin + "/images/svg/call.svg"}
              alt="profile"
              className="icon_svg"
            />
          </div> */}
        </div>
      </div>
      {/* <div className="container2">
        <div className="bar_container">
          <p className="mystat_p2" style={{ marginTop: "15px" }}>
            Overview
          </p>
          <Chart
            options={apexBarFse.options}
            series={apexBarFse.series}
            type="bar"
            height={350}
            width={2200}
          />
        </div>
        <div className="calender_container">

          <Calender2 />
          <div style={{ margin: "20px" }}>Performance View</div>
          <Chart
            options={apexMultiline2.options}
            series={apexMultiline2.series}
            type="line"
            height={330}
            width={370}
          />
        </div>
      </div>
       */}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "0px 30px",
        }}
      >
        <select
          className="nav-tab"
          onChange={(e) => {
            const value = e.target.value;
            if (value === "active") {
              setActiveFse(true);
              setFseCheckedIn(false);
              setInactiveFse(false);
            } else if (value === "inactive") {
              setFseCheckedIn(false);
              setInactiveFse(true);
              setActiveFse(false);
            } else if (value === "fseCheckedIn") {
              setFseCheckedIn(true);
              setInactiveFse(false);
              setActiveFse(false);
            } else {
              setFseCheckedIn(false);
              setInactiveFse(false);
              setActiveFse(false);
            }
          }}
        >
          <option value="active">ACTIVE</option>
          <option value="inactive">INACTIVE</option>
          <option value="all">ALL</option>
          <option value="fseCheckedIn">CHECKED IN</option>
        </select>
      </div>

      {/* <div className="tl_list_row2">
        <div className="fseListTitle">List of FSE</div>

        <div className="myRow">
          <div
            className={`nav-tab ${activeFse ? "active" : ""}`}
            onClick={() => {
              setActiveFse(true);
              setInactiveFse(false);
            }}
          >
            ACTIVE
          </div>
          <div
            className={`nav-tab ${inactiveFse ? "active" : ""}`}
            onClick={() => {
              setInactiveFse(true);
              setActiveFse(false);
            }}
          >
            INACTIVE
          </div>
          <div
            className={`nav-tab ${!activeFse && !inactiveFse ? "active" : ""}`}
            onClick={() => {
              setInactiveFse(false);
              setActiveFse(false);
            }}
          >
            ALL
          </div>
        </div>
      </div> */}
      {/* <div className="tl_list_row2">
        <div className="myTeam_card">
          <p className="mystat_p2" style={{ marginTop: "15px" }}>
            Daywise overview
          </p>
          <Chart
            options={apexMultiline.options}
            series={apexMultiline.series}
            type="line"
            height={250}
          />
        </div>
      </div> */}

      <div className="dashtl1_row1">
        {/* {1 === 0 ? (
          <div className="dash_row4">
            <></>
          </div>
        ) : ( */}
        {/* {props.fseList.map((fse_data) => (         key={fse_data.user_id}*/}
        {fseDummyData.map(
          (
            {
              is_active,
              tl_name,
              team_size,
              doj,
              location,
              checkin_thismonth,
              checkin_today,
              leads_thismonth,
              leads_today,
              approved_thismonth,
              approved_today,
              total_star,
              onboarded_by,
              onboarded_on,
              inactivated_by,
              fse_id,
              onboard_status,
              profile_image,
              mobile_number,
            },
            index
          ) => (
            <>
              {activeFse &&
              (onboard_status === "onboarded" ||
                onboard_status === "cooling" ||
                onboard_status === "none") ? (
                <>
                  <div className="dashtl_card">
                    <div className="dashtl_card_row1">
                      <div className="dashtl1_card_col1">
                        <div className="dashtl_small_col">
                          <img
                            src={`${profile_image}`}
                            alt="profile"
                            className="profile_tl"
                            onError={(e) => {
                              e.target.src =
                                window.location.origin +
                                "/images/profile_default_new.svg";
                            }}
                          />
                          {onboard_status === "onboarded" ? (
                            <p className="tllist_active">Onboarded</p>
                          ) : (
                            <></>
                          )}
                          {onboard_status === "none" ? (
                            <p
                              className="tllist_active"
                              style={{
                                backgroundColor: "#ebb51b99",
                                color: "#a55d1d",
                              }}
                            >
                              New
                            </p>
                          ) : (
                            <></>
                          )}
                          {onboard_status !== "onboarded" &&
                          onboard_status !== "none" ? (
                            <p
                              className="tllist_active"
                              style={{ color: "red", background: "#ffd9d9" }}
                            >
                              {onboard_status}
                            </p>
                          ) : (
                            <></>
                          )}
                        </div>

                        <div className="dashtl1_card_col2 ">
                          <div
                            className="dashtl_card_row2"
                            style={{ margin: "10px" }}
                          >
                            {/* {1 !== "NA" ? (
                    <img
                      src={window.location.origin + "/images/svg/active.svg"}
                      alt="profile"
                      className="dash_active"
                    />
                  ) : (
                    <img
                      src={window.location.origin + "/images/svg/inactive.svg"}
                      alt="profile"
                      className="dash_active"
                    />
                  )} */}
                            <p className="dashtl_p6">{tl_name}</p>
                          </div>
                          {/* <div className="dashtl_card_row3">
                      <p className="dashtl_card_p1">Team Size:</p>
                      <p className="dashtl_card_p2">{team_size}</p>
                    </div> */}
                          <div className="dashtl_card_row3">
                            <p className="dashtl_card_p1">
                              <img
                                src={
                                  window.location.origin + "/images/fse_id.svg"
                                }
                                alt="fse_id"
                              />
                            </p>
                            <p className="dashtl_card_p2">{fse_id}</p>
                          </div>
                          <div className="dashtl_card_row3">
                            <p className="dashtl_card_p1">
                              <img
                                src={
                                  window.location.origin +
                                  "/images/calendar.svg"
                                }
                                alt="date of joining"
                              />
                            </p>
                            <p className="dashtl_card_p2">{`${doj.substring(
                              8,
                              10
                            )}/${doj.substring(5, 7)}/${doj.substring(
                              0,
                              4
                            )}`}</p>
                          </div>
                          {/* <div className="dashtl_card_row3">
                      <p className="dashtl_card_p1">Location:</p>
                      <p
                        className="dashtl_card_p2"
                        style={{ textTransform: "capitalize" }}
                      >
                        {location}
                      </p>
                    </div> */}
                        </div>
                      </div>

                      <div style={{ display: "flex" }}>
                        {context.fseListApi &&
                        Object.keys(context.fseListApi).length > 0 &&
                        context.fseListApi.checkins
                          .map((obj) => {
                            return obj.user_id + "";
                          })
                          .indexOf(fse_id + "") != -1 ? (
                          <div style={{ display: "flex" }}>
                            <p className="checkedin">
                              {/* {
                          JSON.stringify(context.fseListApi.checkins.filter((obj)=>{
                            return obj.user_id === `${fse_id}`
                          }))
                        } */}
                              {formatAMPM(
                                context.fseListApi.checkins.filter((obj) => {
                                  return obj.user_id === `${fse_id}`;
                                })[0].checkin
                              ) + "  "}
                              {/* {console.log(fse_id,"fseId in fselist comp",context.fseListApi.checkins[0].user_id)} */}
                            </p>
                            <img
                              src={
                                window.location.origin +
                                "/images/greencheck.png"
                              }
                              alt="whether checkedin"
                              height="20px"
                            />
                          </div>
                        ) : null}

                        <div
                          className="dashtl_card_col3"
                          onClick={() => {
                            handleClick(index);
                          }}
                        >
                          <img
                            src={
                              window.location.origin +
                              "/images/svg/view_details.svg"
                            }
                            alt="profile"
                            className="svg_myteam"
                          />

                          {active[index] ? (
                            <div className="dashtl_dropdown">
                              <div className="tl_dropdown">
                                <p
                                  className="myteam_drop_p1"
                                  onClick={() => {
                                    handlePop();
                                    // window.scrollTo(0,document.body.scrollHeight)
                                    fetchCallFseChart(fse_id);
                                    // setChartData();
                                    context.activeFseId = fse_id;
                                    context.fseDetailsPopup.profile_image =
                                      profile_image;
                                    context.fseDetailsPopup.full_name = tl_name;
                                    // context.fseDetailsPopup.location="location";
                                    context.fseDetailsPopup.leads_thismonth =
                                      leads_thismonth;
                                    context.fseDetailsPopup.leads_today =
                                      leads_today;
                                    context.fseDetailsPopup.approved_thismonth =
                                      approved_thismonth;
                                    context.fseDetailsPopup.approved_today =
                                      approved_today;
                                    context.fseDetailsPopup.mobile_number =
                                      mobile_number;

                                    setContext({ ...context });
                                  }}
                                >
                                  View FSE Details
                                </p>
                                <p
                                  className="myteam_drop_p1"
                                  onClick={() => {
                                    // handleFseRemap(fse_id);

                                    context.activeFseId = fse_id;
                                    getAsmList()
                                      .then((r) => {
                                        if (!r.error) {
                                          context.asmList = [...r.tsm];
                                          setContext({ ...context });
                                          if (context.asmList.length > 0) {
                                            console.log(
                                              context.asmList,
                                              "under remap with asmlist from context"
                                            );
                                            setOpenAsmList(true);
                                          }
                                        } else {
                                          context.asmList = [];
                                          setContext({ ...context });
                                        }
                                      })
                                      .catch((err) => {
                                        Swal.fire({
                                          icon: "error",
                                          text: "Network Error: Unable to fetch tsm list",
                                        });
                                      });
                                    setContext({ ...context });
                                  }}
                                >
                                  Remap
                                </p>
                                <p
                                  className="myteam_drop_p1"
                                  onClick={() => {
                                    Swal.fire({
                                      title: `Previous number ${mobile_number} `,
                                      input: "text",
                                      showCancelButton: true,
                                      confirmButtonText: "Update",
                                      showLoaderOnConfirm: true,
                                      preConfirm: (login) => {
                                        return fetch(
                                          `https://nodeapi.taskmo.com/v1/pa/fse/mobile_change`,
                                          {
                                            method: "PUT",
                                            headers: {
                                              "Content-type":
                                                "application/json",
                                            },
                                            body: JSON.stringify({
                                              fse_id: `${fse_id}`,
                                              mobile_number: `${login}`,
                                            }),
                                          }
                                        )
                                          .then((response) => {
                                            if (!response.ok) {
                                              throw new Error(
                                                response.statusText
                                              );
                                            }
                                            return response.json();
                                          })
                                          .catch((error) => {
                                            Swal.showValidationMessage(
                                              `Request failed: ${error}`
                                            );
                                          });
                                      },
                                      allowOutsideClick: () =>
                                        !Swal.isLoading(),
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        Swal.fire({
                                          position: "center",
                                          icon: "success",
                                          title: "Your work has been saved",
                                          showConfirmButton: false,
                                          timer: 1500,
                                        });
                                      }
                                    });
                                    // console.log(fse_id, mobile_number);
                                  }}
                                >
                                  Number Update
                                </p>
                                <p
                                  className="myteam_drop_p1"
                                  onClick={() => {
                                    axios
                                      .put(
                                        `https://nodeapi.taskmo.com/v1/pa/fse/bank_status`,
                                        { fse_id: `${fse_id}` }
                                      )
                                      .then((res) => {
                                        Swal.fire({
                                          icon: "success",
                                          html: `Bank status changed`,
                                        });
                                      })
                                      .catch((error) => {
                                        console.log(error, `i got error`);
                                      });
                                  }}
                                >
                                  Bank Status
                                </p>
                                <p
                                  className="myteam_drop_p1"
                                  onClick={() => {
                                    axios
                                      .get(
                                        `https://nodeapi.taskmo.com/v1/cf/check_wallet_balance?user_id=${fse_id}`
                                      )
                                      .then((res) => {
                                        console.log(
                                          res.data.data,
                                          `i got data`
                                        );
                                        console.log(context, "before");
                                        context.wallet = res.data.data;
                                        setContext({ ...context });
                                        Swal.fire({
                                          icon: "success",
                                          html: `<div>credited : ${context.wallet.credited_amount}<br/>debited : ${context.wallet.debited_amount}<br/>balance : ${context.wallet.wallet_balance}</div>`,
                                        });
                                      })
                                      .catch((error) => {
                                        console.log(error, `i got error`);
                                      });
                                  }}
                                >
                                  Check Balance
                                </p>
                                {onboard_status !== "none" ? (
                                  <p
                                    className="myteam_drop_p1"
                                    onClick={() => {
                                      axios
                                        .put(
                                          `https://nodeapi.taskmo.com/v1/pa/fse/reonboard`,
                                          { fse_id: `${fse_id}` }
                                        )
                                        .then((res) => {
                                          Swal.fire({
                                            icon: "success",
                                            html: `Re-Onboard option is granted`,
                                          });
                                        })
                                        .catch((error) => {
                                          console.log(error, `i got error`);
                                        });
                                      // console.log(onboard_status, "fse-detail");
                                    }}
                                  >
                                    Re-onboarding
                                  </p>
                                ) : null}
                                <p
                                  className="myteam_drop_p1"
                                  style={{
                                    backgroundColor: "rgba(255, 0, 0,0.2)",
                                    color: "black",
                                  }}
                                  onClick={() => {
                                    context.updateOnboardStatusData = {
                                      fse_user_id: fse_id,
                                      hr_admin_id:
                                        localStorage.getItem("hr_admin_id"),
                                      action_type: "inactive",
                                      comment: "",
                                    };
                                    setContext({ ...context });
                                    setOpenInactiveComment(true);
                                    // updateOnboardStatus(fse_id,
                                    //   localStorage.getItem("hr_admin_id"),
                                    //   inactiveComment,
                                    //   'inactive')
                                  }}
                                >
                                  Mark Inactive
                                </p>
                                {/* <p className="myteam_drop_p1">View Profile</p>  */}
                                {/* <p className="myteam_drop_p1">Call FSE</p> */}
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="dashtl_card_row2">
                      <div className="dashtl_smallcard">
                        <p className="dashtl_card_p1">Leads</p>
                        <p className="dashtl_p4">{leads_thismonth}</p>
                        {/* today */}
                        {leads_today !== 0 ? (
                          <p className="dashtl_p5">+{leads_today}</p>
                        ) : (
                          <p className="dashtl_p5" style={{ color: "#FF3B3B" }}>
                            00
                          </p>
                        )}
                      </div>
                      <div className="dashtl_smallcard">
                        <p className="dashtl_card_p1">Approved</p>
                        <p className="dashtl_p4">{approved_thismonth}</p>
                        {/* today */}
                        {approved_today !== 0 ? (
                          <p className="dashtl_p5">+{approved_today}</p>
                        ) : (
                          <p className="dashtl_p5" style={{ color: "#FF3B3B" }}>
                            00
                          </p>
                        )}
                      </div>
                      <div className="dashtl_smallcard">
                        <p className="dashtl_card_p1">Checkins</p>
                        <p className="dashtl_p4">
                          {context.fseWiseCurMonthCheckinsApi &&
                          context.fseWiseCurMonthCheckinsApi.length > 0 &&
                          context.fseWiseCurMonthCheckinsApi
                            .map((obj) => {
                              return obj.user_id + "";
                            })
                            .indexOf(fse_id + "") != -1 ? (
                            <>
                              {
                                context.fseWiseCurMonthCheckinsApi.filter(
                                  (obj) => {
                                    return obj.user_id === `${fse_id}`;
                                  }
                                )[0].checkin_count
                              }
                            </>
                          ) : null}
                        </p>

                        <p className="dashtl_p4" style={{ fontSize: "10px" }}>
                          {approved_today >= 5
                            ? `F +${getXleads(approved_today)}`
                            : null}
                          {approved_today >= 3 && approved_today < 5
                            ? `H +${getXleads(approved_today)}`
                            : null}
                          {approved_today >= 1 && approved_today < 3
                            ? `P +${getXleads(approved_today)}`
                            : null}
                          {approved_today === 0 ? "Absent" : null}
                          {/* {JSON.stringify({data:context.fseWiseCurMonthCheckinsApi
                    .map((obj) => {
                      return obj.user_id + "";
                    })
                    .indexOf(fse_id + "") != -1,data1:context.fseWiseCurMonthCheckinsApi})} */}

                          {console.log(typeof leads_thismonth)}
                          {/* {leads_today>=9 && leads_today<10?"2 Stars":null} */}
                          {/* {leads_today>=7 && leads_today<9?"1 Star":null} */}
                        </p>

                        {/* {total_star_today !== "0" ? (
                    <p className="dashtl_p5">+{}</p>
                  ) : (
                    <p className="dashtl_p5" style={{ color: "#FF3B3B" }}>
                      {total_star_today}
                    </p>
                  )} */}
                      </div>
                    </div>
                    <div className="my-card-footer">
                      <div>
                        Onboarded by: {onboarded_by} &nbsp; &nbsp;{" "}
                        {onboarded_on} &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                      </div>
                      {/* <div>
                  {inactivated_by !== "" ? <><span style={{color:"red"}}>Inactivated by :</span> {inactivated_by}</> : <></>}
                </div> */}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {inactiveFse &&
                  onboard_status !== "onboarded" &&
                  onboard_status !== "cooling" &&
                  onboard_status !== "none" ? (
                    <>
                      <div className="dashtl_card">
                        <div className="dashtl_card_row1">
                          <div className="dashtl1_card_col1">
                            <div className="dashtl_small_col">
                              <img
                                src={`${profile_image}`}
                                alt="profile"
                                className="profile_tl"
                                onError={(e) => {
                                  e.target.src =
                                    window.location.origin +
                                    "/images/profile_default_new.svg";
                                }}
                              />
                              {onboard_status === "onboarded" ? (
                                <p className="tllist_active">Onboarded</p>
                              ) : (
                                <></>
                              )}
                              {onboard_status === "none" ? (
                                <p
                                  className="tllist_active"
                                  style={{
                                    backgroundColor: "#ebb51b99",
                                    color: "#a55d1d",
                                  }}
                                >
                                  New
                                </p>
                              ) : (
                                <></>
                              )}
                              {onboard_status !== "onboarded" &&
                              onboard_status !== "none" ? (
                                <p
                                  className="tllist_active"
                                  style={{
                                    color: "red",
                                    background: "#ffd9d9",
                                  }}
                                >
                                  {onboard_status}
                                </p>
                              ) : (
                                <></>
                              )}
                            </div>

                            <div className="dashtl1_card_col2 ">
                              <div
                                className="dashtl_card_row2"
                                style={{ margin: "10px" }}
                              >
                                {/* {1 !== "NA" ? (
                    <img
                      src={window.location.origin + "/images/svg/active.svg"}
                      alt="profile"
                      className="dash_active"
                    />
                  ) : (
                    <img
                      src={window.location.origin + "/images/svg/inactive.svg"}
                      alt="profile"
                      className="dash_active"
                    />
                  )} */}
                                <p className="dashtl_p6">{tl_name}</p>
                              </div>
                              {/* <div className="dashtl_card_row3">
                      <p className="dashtl_card_p1">Team Size:</p>
                      <p className="dashtl_card_p2">{team_size}</p>
                    </div> */}
                              <div className="dashtl_card_row3">
                                <p className="dashtl_card_p1">
                                  <img
                                    src={
                                      window.location.origin +
                                      "/images/fse_id.svg"
                                    }
                                    alt="fse_id"
                                  />
                                </p>
                                <p className="dashtl_card_p2">{fse_id}</p>
                              </div>
                              <div className="dashtl_card_row3">
                                <p className="dashtl_card_p1">
                                  <img
                                    src={
                                      window.location.origin +
                                      "/images/calendar.svg"
                                    }
                                    alt="date of joining"
                                  />
                                </p>
                                <p className="dashtl_card_p2">{`${doj.substring(
                                  8,
                                  10
                                )}/${doj.substring(5, 7)}/${doj.substring(
                                  0,
                                  4
                                )}`}</p>
                              </div>
                              {/* <div className="dashtl_card_row3">
                      <p className="dashtl_card_p1">Location:</p>
                      <p
                        className="dashtl_card_p2"
                        style={{ textTransform: "capitalize" }}
                      >
                        {location}
                      </p>
                    </div> */}
                            </div>
                          </div>

                          <div style={{ display: "flex" }}>
                            {context.fseListApi &&
                            Object.keys(context.fseListApi).length > 0 &&
                            context.fseListApi.checkins
                              .map((obj) => {
                                return obj.user_id + "";
                              })
                              .indexOf(fse_id + "") != -1 ? (
                              <div style={{ display: "flex" }}>
                                <p className="checkedin">
                                  {/* {
                          JSON.stringify(context.fseListApi.checkins.filter((obj)=>{
                            return obj.user_id === `${fse_id}`
                          }))
                        } */}
                                  {formatAMPM(
                                    context.fseListApi.checkins.filter(
                                      (obj) => {
                                        return obj.user_id === `${fse_id}`;
                                      }
                                    )[0].checkin
                                  ) + "  "}
                                  {/* {console.log(fse_id,"fseId in fselist comp",context.fseListApi.checkins[0].user_id)} */}
                                </p>
                                <img
                                  src={
                                    window.location.origin +
                                    "/images/greencheck.png"
                                  }
                                  alt="whether checkedin"
                                  height="20px"
                                />
                              </div>
                            ) : null}

                            <div
                              className="dashtl_card_col3"
                              onClick={() => {
                                handleClick(index);
                              }}
                            >
                              <img
                                src={
                                  window.location.origin +
                                  "/images/svg/view_details.svg"
                                }
                                alt="profile"
                                className="svg_myteam"
                              />

                              {active[index] ? (
                                <div className="dashtl_dropdown">
                                  <div className="tl_dropdown">
                                    <p
                                      className="myteam_drop_p1"
                                      onClick={() => {
                                        handlePop();
                                        // window.scrollTo(0,document.body.scrollHeight)
                                        fetchCallFseChart(fse_id);
                                        // setChartData();
                                        context.activeFseId = fse_id;
                                        context.fseDetailsPopup.profile_image =
                                          profile_image;
                                        context.fseDetailsPopup.full_name =
                                          tl_name;
                                        // context.fseDetailsPopup.location="location";
                                        context.fseDetailsPopup.leads_thismonth =
                                          leads_thismonth;
                                        context.fseDetailsPopup.leads_today =
                                          leads_today;
                                        context.fseDetailsPopup.approved_thismonth =
                                          approved_thismonth;
                                        context.fseDetailsPopup.approved_today =
                                          approved_today;
                                        context.fseDetailsPopup.mobile_number =
                                          mobile_number;

                                        setContext({ ...context });
                                      }}
                                    >
                                      View FSE Details
                                    </p>
                                    <p
                                      className="myteam_drop_p1"
                                      onClick={() => {
                                        // handleFseRemap(fse_id);

                                        context.activeFseId = fse_id;
                                        getAsmList()
                                          .then((r) => {
                                            if (!r.error) {
                                              context.asmList = [...r.tsm];
                                              setContext({ ...context });
                                              if (context.asmList.length > 0) {
                                                console.log(
                                                  context.asmList,
                                                  "under remap with asmlist from context"
                                                );
                                                setOpenAsmList(true);
                                              }
                                            } else {
                                              context.asmList = [];
                                              setContext({ ...context });
                                            }
                                          })
                                          .catch((err) => {
                                            Swal.fire({
                                              icon: "error",
                                              text: "Network Error: Unable to fetch tsm list",
                                            });
                                          });
                                        setContext({ ...context });
                                      }}
                                    >
                                      Remap
                                    </p>
                                    <p
                                      className="myteam_drop_p1"
                                      onClick={() => {
                                        Swal.fire({
                                          title: `Previous number ${mobile_number} `,
                                          input: "text",
                                          showCancelButton: true,
                                          confirmButtonText: "Update",
                                          showLoaderOnConfirm: true,
                                          preConfirm: (login) => {
                                            return fetch(
                                              `https://nodeapi.taskmo.com/v1/pa/fse/mobile_change`,
                                              {
                                                method: "PUT",
                                                headers: {
                                                  "Content-type":
                                                    "application/json",
                                                },
                                                body: JSON.stringify({
                                                  fse_id: `${fse_id}`,
                                                  mobile_number: `${login}`,
                                                }),
                                              }
                                            )
                                              .then((response) => {
                                                if (!response.ok) {
                                                  throw new Error(
                                                    response.statusText
                                                  );
                                                }
                                                return response.json();
                                              })
                                              .catch((error) => {
                                                Swal.showValidationMessage(
                                                  `Request failed: ${error}`
                                                );
                                              });
                                          },
                                          allowOutsideClick: () =>
                                            !Swal.isLoading(),
                                        }).then((result) => {
                                          if (result.isConfirmed) {
                                            Swal.fire({
                                              position: "center",
                                              icon: "success",
                                              title: "Your work has been saved",
                                              showConfirmButton: false,
                                              timer: 1500,
                                            });
                                          }
                                        });
                                        // console.log(fse_id, mobile_number);
                                      }}
                                    >
                                      Number Update
                                    </p>
                                    <p
                                      className="myteam_drop_p1"
                                      onClick={() => {
                                        axios
                                          .put(
                                            `https://nodeapi.taskmo.com/v1/pa/fse/bank_status`,
                                            { fse_id: `${fse_id}` }
                                          )
                                          .then((res) => {
                                            Swal.fire({
                                              icon: "success",
                                              html: `Bank status changed`,
                                            });
                                          })
                                          .catch((error) => {
                                            console.log(error, `i got error`);
                                          });
                                      }}
                                    >
                                      Bank Status
                                    </p>
                                    <p
                                      className="myteam_drop_p1"
                                      onClick={() => {
                                        axios
                                          .get(
                                            `https://nodeapi.taskmo.com/v1/cf/check_wallet_balance?user_id=${fse_id}`
                                          )
                                          .then((res) => {
                                            console.log(
                                              res.data.data,
                                              `i got data`
                                            );
                                            console.log(context, "before");
                                            context.wallet = res.data.data;
                                            setContext({ ...context });
                                            Swal.fire({
                                              icon: "success",
                                              html: `<div>credited : ${context.wallet.credited_amount}<br/>debited : ${context.wallet.debited_amount}<br/>balance : ${context.wallet.wallet_balance}</div>`,
                                            });
                                          })
                                          .catch((error) => {
                                            console.log(error, `i got error`);
                                          });
                                      }}
                                    >
                                      Check Balance
                                    </p>
                                    {onboard_status !== "none" ? (
                                      <p
                                        className="myteam_drop_p1"
                                        onClick={() => {
                                          axios
                                            .put(
                                              `https://nodeapi.taskmo.com/v1/pa/fse/reonboard`,
                                              { fse_id: `${fse_id}` }
                                            )
                                            .then((res) => {
                                              Swal.fire({
                                                icon: "success",
                                                html: `Re-Onboard option is granted`,
                                              });
                                            })
                                            .catch((error) => {
                                              console.log(error, `i got error`);
                                            });
                                          // console.log(onboard_status, "fse-detail");
                                        }}
                                      >
                                        Re-onboarding
                                      </p>
                                    ) : null}
                                    <p
                                      className="myteam_drop_p1"
                                      style={{
                                        backgroundColor: "rgba(0, 255, 0,0.2)",
                                        color: "black",
                                      }}
                                      onClick={() => {
                                        context.updateOnboardStatusData = {
                                          fse_user_id: fse_id,
                                          hr_admin_id:
                                            localStorage.getItem("hr_admin_id"),
                                          action_type: "active",
                                          comment: "",
                                        };
                                        setContext({ ...context });
                                        setOpenActiveComment(true);
                                        // updateOnboardStatus(fse_id,
                                        //   localStorage.getItem("hr_admin_id"),
                                        //   inactiveComment,
                                        //   'inactive')
                                      }}
                                    >
                                      Mark Active
                                    </p>
                                    {/* <p className="myteam_drop_p1" onClick={updateOnboardStatus}>Mark Active</p>  */}

                                    {/* <p className="myteam_drop_p1">View Profile</p>  */}
                                    {/* <p className="myteam_drop_p1">Call FSE</p> */}
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="dashtl_card_row2">
                          <div className="dashtl_smallcard">
                            <p className="dashtl_card_p1">Leads</p>
                            <p className="dashtl_p4">{leads_thismonth}</p>
                            {/* today */}
                            {leads_today !== 0 ? (
                              <p className="dashtl_p5">+{leads_today}</p>
                            ) : (
                              <p
                                className="dashtl_p5"
                                style={{ color: "#FF3B3B" }}
                              >
                                00
                              </p>
                            )}
                          </div>
                          <div className="dashtl_smallcard">
                            <p className="dashtl_card_p1">Approved</p>
                            <p className="dashtl_p4">{approved_thismonth}</p>
                            {/* today */}
                            {approved_today !== 0 ? (
                              <p className="dashtl_p5">+{approved_today}</p>
                            ) : (
                              <p
                                className="dashtl_p5"
                                style={{ color: "#FF3B3B" }}
                              >
                                00
                              </p>
                            )}
                          </div>
                          <div className="dashtl_smallcard">
                            <p className="dashtl_card_p1">Checkins</p>
                            <p className="dashtl_p4">
                              {context.fseWiseCurMonthCheckinsApi &&
                              context.fseWiseCurMonthCheckinsApi.length > 0 &&
                              context.fseWiseCurMonthCheckinsApi
                                .map((obj) => {
                                  return obj.user_id + "";
                                })
                                .indexOf(fse_id + "") != -1 ? (
                                <>
                                  {
                                    context.fseWiseCurMonthCheckinsApi.filter(
                                      (obj) => {
                                        return obj.user_id === `${fse_id}`;
                                      }
                                    )[0].checkin_count
                                  }
                                </>
                              ) : null}
                            </p>

                            <p
                              className="dashtl_p4"
                              style={{ fontSize: "10px" }}
                            >
                              {approved_today >= 5
                                ? `F +${getXleads(approved_today)}`
                                : null}
                              {approved_today >= 3 && approved_today < 5
                                ? `H +${getXleads(approved_today)}`
                                : null}
                              {approved_today >= 1 && approved_today < 3
                                ? `P +${getXleads(approved_today)}`
                                : null}
                              {approved_today === 0 ? "Absent" : null}
                              {/* {JSON.stringify({data:context.fseWiseCurMonthCheckinsApi
                    .map((obj) => {
                      return obj.user_id + "";
                    })
                    .indexOf(fse_id + "") != -1,data1:context.fseWiseCurMonthCheckinsApi})} */}

                              {console.log(typeof leads_thismonth)}
                              {/* {leads_today>=9 && leads_today<10?"2 Stars":null} */}
                              {/* {leads_today>=7 && leads_today<9?"1 Star":null} */}
                            </p>

                            {/* {total_star_today !== "0" ? (
                    <p className="dashtl_p5">+{}</p>
                  ) : (
                    <p className="dashtl_p5" style={{ color: "#FF3B3B" }}>
                      {total_star_today}
                    </p>
                  )} */}
                          </div>
                        </div>
                        <div className="my-card-footer">
                          <div>
                            Onboarded by: {onboarded_by} &nbsp; &nbsp;{" "}
                            {onboarded_on} &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                          </div>
                          {/* <div>
                  {inactivated_by !== "" ? <><span style={{color:"red"}}>Inactivated by :</span> {inactivated_by}</> : <></>}
                </div> */}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {!activeFse && !inactiveFse ? (
                        <div className="dashtl_card">
                          <div className="dashtl_card_row1">
                            <div className="dashtl1_card_col1">
                              <div className="dashtl_small_col">
                                <img
                                  src={`${profile_image}`}
                                  alt="profile"
                                  className="profile_tl"
                                  onError={(e) => {
                                    e.target.src =
                                      window.location.origin +
                                      "/images/profile_default_new.svg";
                                  }}
                                />
                                {onboard_status === "onboarded" ? (
                                  <p className="tllist_active">Onboarded</p>
                                ) : (
                                  <></>
                                )}
                                {onboard_status === "none" ? (
                                  <p
                                    className="tllist_active"
                                    style={{
                                      backgroundColor: "#ebb51b99",
                                      color: "#a55d1d",
                                    }}
                                  >
                                    New
                                  </p>
                                ) : (
                                  <></>
                                )}
                                {onboard_status !== "onboarded" &&
                                onboard_status !== "none" ? (
                                  <p
                                    className="tllist_active"
                                    style={{
                                      color: "red",
                                      background: "#ffd9d9",
                                    }}
                                  >
                                    {onboard_status}
                                  </p>
                                ) : (
                                  <></>
                                )}
                              </div>

                              <div className="dashtl1_card_col2 ">
                                <div
                                  className="dashtl_card_row2"
                                  style={{ margin: "10px" }}
                                >
                                  {/* {1 !== "NA" ? (
                    <img
                      src={window.location.origin + "/images/svg/active.svg"}
                      alt="profile"
                      className="dash_active"
                    />
                  ) : (
                    <img
                      src={window.location.origin + "/images/svg/inactive.svg"}
                      alt="profile"
                      className="dash_active"
                    />
                  )} */}
                                  <p className="dashtl_p6">{tl_name}</p>
                                </div>
                                {/* <div className="dashtl_card_row3">
                      <p className="dashtl_card_p1">Team Size:</p>
                      <p className="dashtl_card_p2">{team_size}</p>
                    </div> */}
                                <div className="dashtl_card_row3">
                                  <p className="dashtl_card_p1">
                                    <img
                                      src={
                                        window.location.origin +
                                        "/images/fse_id.svg"
                                      }
                                      alt="fse_id"
                                    />
                                  </p>
                                  <p className="dashtl_card_p2">{fse_id}</p>
                                </div>
                                <div className="dashtl_card_row3">
                                  <p className="dashtl_card_p1">
                                    <img
                                      src={
                                        window.location.origin +
                                        "/images/calendar.svg"
                                      }
                                      alt="date of joining"
                                    />
                                  </p>
                                  <p className="dashtl_card_p2">{`${doj.substring(
                                    8,
                                    10
                                  )}/${doj.substring(5, 7)}/${doj.substring(
                                    0,
                                    4
                                  )}`}</p>
                                </div>
                                {/* <div className="dashtl_card_row3">
                      <p className="dashtl_card_p1">Location:</p>
                      <p
                        className="dashtl_card_p2"
                        style={{ textTransform: "capitalize" }}
                      >
                        {location}
                      </p>
                    </div> */}
                              </div>
                            </div>

                            <div style={{ display: "flex" }}>
                              {context.fseListApi &&
                              Object.keys(context.fseListApi).length > 0 &&
                              context.fseListApi.checkins
                                .map((obj) => {
                                  return obj.user_id + "";
                                })
                                .indexOf(fse_id + "") != -1 ? (
                                <div style={{ display: "flex" }}>
                                  <p className="checkedin">
                                    {/* {
                          JSON.stringify(context.fseListApi.checkins.filter((obj)=>{
                            return obj.user_id === `${fse_id}`
                          }))
                        } */}
                                    {formatAMPM(
                                      context.fseListApi.checkins.filter(
                                        (obj) => {
                                          return obj.user_id === `${fse_id}`;
                                        }
                                      )[0].checkin
                                    ) + "  "}
                                    {/* {console.log(fse_id,"fseId in fselist comp",context.fseListApi.checkins[0].user_id)} */}
                                  </p>
                                  <img
                                    src={
                                      window.location.origin +
                                      "/images/greencheck.png"
                                    }
                                    alt="whether checkedin"
                                    height="20px"
                                  />
                                </div>
                              ) : null}

                              <div
                                className="dashtl_card_col3"
                                onClick={() => {
                                  handleClick(index);
                                }}
                              >
                                <img
                                  src={
                                    window.location.origin +
                                    "/images/svg/view_details.svg"
                                  }
                                  alt="profile"
                                  className="svg_myteam"
                                />

                                {active[index] ? (
                                  <div className="dashtl_dropdown">
                                    <div className="tl_dropdown">
                                      <p
                                        className="myteam_drop_p1"
                                        onClick={() => {
                                          handlePop();
                                          // window.scrollTo(0,document.body.scrollHeight)
                                          fetchCallFseChart(fse_id);
                                          // setChartData();
                                          context.activeFseId = fse_id;
                                          context.fseDetailsPopup.profile_image =
                                            profile_image;
                                          context.fseDetailsPopup.full_name =
                                            tl_name;
                                          // context.fseDetailsPopup.location="location";
                                          context.fseDetailsPopup.leads_thismonth =
                                            leads_thismonth;
                                          context.fseDetailsPopup.leads_today =
                                            leads_today;
                                          context.fseDetailsPopup.approved_thismonth =
                                            approved_thismonth;
                                          context.fseDetailsPopup.approved_today =
                                            approved_today;
                                          context.fseDetailsPopup.mobile_number =
                                            mobile_number;

                                          setContext({ ...context });
                                        }}
                                      >
                                        View FSE Details
                                      </p>
                                      <p
                                        className="myteam_drop_p1"
                                        onClick={() => {
                                          // handleFseRemap(fse_id);

                                          context.activeFseId = fse_id;
                                          getAsmList()
                                            .then((r) => {
                                              if (!r.error) {
                                                context.asmList = [...r.tsm];
                                                setContext({ ...context });
                                                if (
                                                  context.asmList.length > 0
                                                ) {
                                                  console.log(
                                                    context.asmList,
                                                    "under remap with asmlist from context"
                                                  );
                                                  setOpenAsmList(true);
                                                }
                                              } else {
                                                context.asmList = [];
                                                setContext({ ...context });
                                              }
                                            })
                                            .catch((err) => {
                                              Swal.fire({
                                                icon: "error",
                                                text: "Network Error: Unable to fetch tsm list",
                                              });
                                            });
                                          setContext({ ...context });
                                        }}
                                      >
                                        Remap
                                      </p>
                                      <p
                                        className="myteam_drop_p1"
                                        onClick={() => {
                                          Swal.fire({
                                            title: `Previous number ${mobile_number} `,
                                            input: "text",
                                            showCancelButton: true,
                                            confirmButtonText: "Update",
                                            showLoaderOnConfirm: true,
                                            preConfirm: (login) => {
                                              return fetch(
                                                `https://nodeapi.taskmo.com/v1/pa/fse/mobile_change`,
                                                {
                                                  method: "PUT",
                                                  headers: {
                                                    "Content-type":
                                                      "application/json",
                                                  },
                                                  body: JSON.stringify({
                                                    fse_id: `${fse_id}`,
                                                    mobile_number: `${login}`,
                                                  }),
                                                }
                                              )
                                                .then((response) => {
                                                  if (!response.ok) {
                                                    throw new Error(
                                                      response.statusText
                                                    );
                                                  }
                                                  return response.json();
                                                })
                                                .catch((error) => {
                                                  Swal.showValidationMessage(
                                                    `Request failed: ${error}`
                                                  );
                                                });
                                            },
                                            allowOutsideClick: () =>
                                              !Swal.isLoading(),
                                          }).then((result) => {
                                            if (result.isConfirmed) {
                                              Swal.fire({
                                                position: "center",
                                                icon: "success",
                                                title:
                                                  "Your work has been saved",
                                                showConfirmButton: false,
                                                timer: 1500,
                                              });
                                            }
                                          });
                                          // console.log(fse_id, mobile_number);
                                        }}
                                      >
                                        Number Update
                                      </p>
                                      <p
                                        className="myteam_drop_p1"
                                        onClick={() => {
                                          axios
                                            .put(
                                              `https://nodeapi.taskmo.com/v1/pa/fse/bank_status`,
                                              { fse_id: `${fse_id}` }
                                            )
                                            .then((res) => {
                                              Swal.fire({
                                                icon: "success",
                                                html: `Bank status changed`,
                                              });
                                            })
                                            .catch((error) => {
                                              console.log(error, `i got error`);
                                            });
                                        }}
                                      >
                                        Bank Status
                                      </p>
                                      <p
                                        className="myteam_drop_p1"
                                        onClick={() => {
                                          axios
                                            .get(
                                              `https://nodeapi.taskmo.com/v1/cf/check_wallet_balance?user_id=${fse_id}`
                                            )
                                            .then((res) => {
                                              console.log(
                                                res.data.data,
                                                `i got data`
                                              );
                                              console.log(context, "before");
                                              context.wallet = res.data.data;
                                              setContext({ ...context });
                                              Swal.fire({
                                                icon: "success",
                                                html: `<div>credited : ${context.wallet.credited_amount}<br/>debited : ${context.wallet.debited_amount}<br/>balance : ${context.wallet.wallet_balance}</div>`,
                                              });
                                            })
                                            .catch((error) => {
                                              console.log(error, `i got error`);
                                            });
                                        }}
                                      >
                                        Check Balance
                                      </p>
                                      {onboard_status !== "none" ? (
                                        <p
                                          className="myteam_drop_p1"
                                          onClick={() => {
                                            axios
                                              .put(
                                                `https://nodeapi.taskmo.com/v1/pa/fse/reonboard`,
                                                { fse_id: `${fse_id}` }
                                              )
                                              .then((res) => {
                                                Swal.fire({
                                                  icon: "success",
                                                  html: `Re-Onboard option is granted`,
                                                });
                                              })
                                              .catch((error) => {
                                                console.log(
                                                  error,
                                                  `i got error`
                                                );
                                              });
                                            // console.log(onboard_status, "fse-detail");
                                          }}
                                        >
                                          Re-onboarding
                                        </p>
                                      ) : null}
                                      {/* <p
                                        className="myteam_drop_p1"
                                        onClick={() => {
                                          context.updateOnboardStatusData = {
                                            fse_user_id: fse_id,
                                            hr_admin_id:
                                              localStorage.getItem(
                                                "hr_admin_id"
                                              ),
                                            action_type: "inactive",
                                            comment: "",
                                          };
                                          setContext({ ...context });
                                          setOpenInactiveComment(true);
                                          // updateOnboardStatus(fse_id,
                                          //   localStorage.getItem("hr_admin_id"),
                                          //   inactiveComment,
                                          //   'inactive')
                                        }}
                                      >
                                        Mark Inactive
                                      </p> */}
                                      {/* <p className="myteam_drop_p1">View Profile</p>  */}
                                      {/* <p className="myteam_drop_p1">Call FSE</p> */}
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="dashtl_card_row2">
                            <div className="dashtl_smallcard">
                              <p className="dashtl_card_p1">Leads</p>
                              <p className="dashtl_p4">{leads_thismonth}</p>
                              {/* today */}
                              {leads_today !== 0 ? (
                                <p className="dashtl_p5">+{leads_today}</p>
                              ) : (
                                <p
                                  className="dashtl_p5"
                                  style={{ color: "#FF3B3B" }}
                                >
                                  00
                                </p>
                              )}
                            </div>
                            <div className="dashtl_smallcard">
                              <p className="dashtl_card_p1">Approved</p>
                              <p className="dashtl_p4">{approved_thismonth}</p>
                              {/* today */}
                              {approved_today !== 0 ? (
                                <p className="dashtl_p5">+{approved_today}</p>
                              ) : (
                                <p
                                  className="dashtl_p5"
                                  style={{ color: "#FF3B3B" }}
                                >
                                  00
                                </p>
                              )}
                            </div>
                            <div className="dashtl_smallcard">
                              <p className="dashtl_card_p1">Checkins</p>
                              <p className="dashtl_p4">
                                {context.fseWiseCurMonthCheckinsApi &&
                                context.fseWiseCurMonthCheckinsApi.length > 0 &&
                                context.fseWiseCurMonthCheckinsApi
                                  .map((obj) => {
                                    return obj.user_id + "";
                                  })
                                  .indexOf(fse_id + "") != -1 ? (
                                  <>
                                    {
                                      context.fseWiseCurMonthCheckinsApi.filter(
                                        (obj) => {
                                          return obj.user_id === `${fse_id}`;
                                        }
                                      )[0].checkin_count
                                    }
                                  </>
                                ) : null}
                              </p>

                              <p
                                className="dashtl_p4"
                                style={{ fontSize: "10px" }}
                              >
                                {approved_today >= 5
                                  ? `F +${getXleads(approved_today)}`
                                  : null}
                                {approved_today >= 3 && approved_today < 5
                                  ? `H +${getXleads(approved_today)}`
                                  : null}
                                {approved_today >= 1 && approved_today < 3
                                  ? `P +${getXleads(approved_today)}`
                                  : null}
                                {approved_today === 0 ? "Absent" : null}
                                {/* {JSON.stringify({data:context.fseWiseCurMonthCheckinsApi
                    .map((obj) => {
                      return obj.user_id + "";
                    })
                    .indexOf(fse_id + "") != -1,data1:context.fseWiseCurMonthCheckinsApi})} */}

                                {console.log(typeof leads_thismonth)}
                                {/* {leads_today>=9 && leads_today<10?"2 Stars":null} */}
                                {/* {leads_today>=7 && leads_today<9?"1 Star":null} */}
                              </p>

                              {/* {total_star_today !== "0" ? (
                    <p className="dashtl_p5">+{}</p>
                  ) : (
                    <p className="dashtl_p5" style={{ color: "#FF3B3B" }}>
                      {total_star_today}
                    </p>
                  )} */}
                            </div>
                          </div>
                          <div className="my-card-footer">
                            <div>
                              Onboarded by: {onboarded_by} &nbsp; &nbsp;{" "}
                              {onboarded_on} &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                            </div>
                            {/* <div>
                  {inactivated_by !== "" ? <><span style={{color:"red"}}>Inactivated by :</span> {inactivated_by}</> : <></>}
                </div> */}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )
        )}

        {/* ))} */}

        {/* // )} */}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modalStyle1}
      >
        <div className="model_contain">
          <div
            className="dashtl_header1"
            style={{ backgroundColor: "#555555" }}
            onClick={handleClose}
          >
            {/* <div className="tl_list_row1">
              <img
                src={window.location.origin + "/images/svg/back_button.svg"}
                alt="profile"
                className="back_butt"
              />
              <div className="track_tl">
                <div className="training_assign">
                  <img
                    src={
                      window.location.origin +
                      "/images/svg/training_assigned.svg"
                    }
                    alt="profile"
                    className="complete_track"
                  />
                  <p className="training_p1">Training Assigned</p>
                </div>

                <div className="dash_tl_line"></div>
                <div className="training_assign">
                  <img
                    src={
                      window.location.origin +
                      "/images/svg/training completed.svg"
                    }
                    alt="profile"
                    className="complete_track"
                  />
                  <p className="training_p1" style={{ color: "#777777" }}>
                    Training Completed
                  </p>
                </div>

                <div className="dash_tl_line1"></div>
                <div className="training_assign">
                  <img
                    src={
                      window.location.origin +
                      "/images/svg/training completed.svg"
                    }
                    alt="profile"
                    className="complete_track"
                  />
                  <p className="training_p1" style={{ color: "#777777" }}>
                    Payment
                  </p>
                </div>
              </div>
            </div> */}

            <div className="tl_list_row1">
              <div className="dashtl_h_col2">
                <img
                  // src={window.location.origin + "/images/png/profile1.png"}
                  src={context.fseDetailsPopup.profile_image}
                  alt="profile"
                  className="profile_tl"
                  onError={(e) => {
                    e.target.src =
                      window.location.origin +
                      "/images/profile_default_new.svg";
                  }}
                />
                <div className="dashtl_h_row1"></div>
                <div className="dashtl_h_row2">
                  <p className="dashtl_h_p1">
                    {context.fseDetailsPopup.full_name}
                  </p>
                  <p className="dashtl_h_p5">FSE</p>
                  <p className="dashtl_h_p2">
                    {context.fseDetailsPopup.mobile_number}
                  </p>
                </div>
              </div>
              <div className="dashtl_h_col3">
                <div
                  className="dashtl_h_cards"
                  style={{ background: "#747474" }}
                >
                  <p className="dashtl_h_p3">Leads</p>
                  <p className="dashtl_p4" style={{ color: "white" }}>
                    {context.fseDetailsPopup.leads_thismonth}
                  </p>
                  {context.fseDetailsPopup.leads_today !== 0 ? (
                    <p className="dashtl_p5">
                      +{context.fseDetailsPopup.leads_today}
                    </p>
                  ) : (
                    <p className="dashtl_p5" style={{ color: "#FF3B3B" }}>
                      00
                    </p>
                  )}
                </div>
                <div
                  className="dashtl_h_cards"
                  style={{ background: "#747474" }}
                >
                  <p className="dashtl_h_p3">QC Approved</p>
                  <p className="dashtl_p4" style={{ color: "white" }}>
                    {context.fseDetailsPopup.approved_thismonth}
                  </p>
                  {context.fseDetailsPopup.approved_today !== 0 ? (
                    <p className="dashtl_p5">
                      +{context.fseDetailsPopup.approved_today}
                    </p>
                  ) : (
                    <p className="dashtl_p5" style={{ color: "#FF3B3B" }}>
                      00
                    </p>
                  )}
                </div>

                <div
                  className="dashtl_h_cards"
                  style={{ background: "#747474" }}
                >
                  <p className="dashtl_h_p3">Checkins</p>
                  <p className="dashtl_p4" style={{ color: "white" }}>
                    {context.fseWiseCurMonthCheckinsApi &&
                    context.fseWiseCurMonthCheckinsApi.length > 0 &&
                    context.fseWiseCurMonthCheckinsApi
                      .map((obj) => {
                        return obj.user_id + "";
                      })
                      .indexOf(context.activeFseId + "") != -1 ? (
                      <>
                        {
                          context.fseWiseCurMonthCheckinsApi.filter((obj) => {
                            return obj.user_id === `${context.activeFseId}`;
                          })[0].checkin_count
                        }
                      </>
                    ) : null}
                  </p>

                  <p
                    className="dashtl_p4"
                    style={{ fontSize: "10px", color: "black" }}
                  >
                    {context.fseDetailsPopup.approved_today >= 5
                      ? `F +${getXleads(
                          context.fseDetailsPopup.approved_today
                        )}`
                      : null}
                    {context.fseDetailsPopup.approved_today >= 3 &&
                    context.fseDetailsPopup.approved_today < 5
                      ? `H +${getXleads(
                          context.fseDetailsPopup.approved_today
                        )}`
                      : null}
                    {context.fseDetailsPopup.approved_today >= 1 &&
                    context.fseDetailsPopup.approved_today < 3
                      ? `P +${getXleads(
                          context.fseDetailsPopup.approved_today
                        )}`
                      : null}
                    {context.fseDetailsPopup.approved_today === 0
                      ? "Absent"
                      : null}
                  </p>
                </div>
              </div>
              {/* <div className="dashtl_h_col4">
                <img
                  src={window.location.origin + "/images/svg/message.svg"}
                  alt="profile"
                  className="icon_svg"
                />
                <img
                  src={window.location.origin + "/images/svg/call.svg"}
                  alt="profile"
                  className="icon_svg"
                />
              </div> */}
            </div>
          </div>
          <div className="container2">
            <div className="bar_container">
              <p className="mystat_p2" style={{ marginTop: "15px" }}>
                Overview
              </p>
              {noChartInfo ? "No leads this month" : ""}
              {renderChart ? (
                <Chart
                  options={apexBarFseState.options}
                  series={apexBarFseState.series}
                  type={`${chartType}`}
                  height={350}
                  width={700}
                />
              ) : null}
            </div>
            <div className="calender_container">
              {/* <CalenderAttend /> */}
              {/* <Calender2 /> */}
              <Calender3 />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default FseList;
