export const getDistinct = (arr,key) => {
  const newArr = [
    ...new Set(
      arr.map((obj) => {
        return obj[`${key}`];
      })
    ),
  ];
  const finalarr = [];
  const flags = new Array(newArr.length).fill(false);
  for (let i = 0; i < newArr.length; i++) {
    for (let j = 0; j < arr.length; j++) {
      if (!flags[i] && newArr[i] === arr[j][`${key}`]) {
        flags[i] = true;
        finalarr.push(arr[j]);
      }
    }
  }
  return finalarr;
};
