import { useState } from "react";
import stateCity from "../dataset/state_city";
import "./AddAsm.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect } from "react";
import { BASE_URL, CM_URL, RM_URL } from "../../utils";
import axios from "axios";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";

function ValidateEmail(email) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true;
  }
  // alert("You have entered an invalid email address!")
  return false;
}

function AddAsm() {
  const { cmIdForAddAsm } = useParams();
  const [formData, setFormData] = useState({
    full_name: "",
    mobile_number: "",
    email_id: "",

    aadhar_number: "",
    type: "",
    cm_id: cmIdForAddAsm,

    profileImage: "",
    state: "",
    city: "",
    pincode: "",
    dateOfOnboard: "",
    doj: "",
    pan_number: "",
    aadhar_front_image: "",
    aadhar_back_image: "",
    dlFrontImage: "",
    dlBackImage: "",
    bankImage: "",
    panImage: "",
    aadharFrontImage: "",
    aadharBackImage: "",
    dl_front_image: "",
    dl_back_image: "",
    bank_statement_image: "",
    pan_image: "",
    profile_image: "",
  });
  const states = stateCity.map((obj) => obj.state);
  const uniqueStates = [...new Set(states)];
  const sortedStates = uniqueStates.sort();
  const [cmList, setCmList] = useState([]);
  useEffect(() => {
    const asyncFunc = async () => {
      const res1 = await fetch(CM_URL);
      const res = await res1.json();
      console.log(res, "cmList");
      if (res.error) {
      } else {
        setCmList([...res.data]);
      }
    };
    asyncFunc();
  }, []);

  // const uploadImage = async (e) => {
  //   try {
  //     console.log(e.target.value, "inside adhar front");
  //     // const axiosData = {
  //     //   "type" : e.target.name,
  //     //   "image" : e.target.value
  //     // }
  //     const axiosData = new FormData();

  //     axiosData.append("image", e.target.files[0]);
  //     axiosData.append("type", `${e.target.name}`);
  //     console.log(e.target.files[0], "axiosData,inside");

  //     const uploadData = await axios
  //       .post("https://app.backend.taskmo.co/hr/upload_image", axiosData)
  //       .then((res) => {
  //         console.log(res.data.path, "successful");
  //         formData[
  //           e.target.name
  //         ] = `https://app.backend.taskmo.co/cm_images/${res.data.path}`;
  //         setFormData({ ...formData });
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  // const ImageUpload = (e) => {
  //   const fileSelect = document.getElementById("fileSelect"),
  //     fileElem = document.getElementById("fileElem");
  //   fileSelect.addEventListener(
  //     "click",
  //     function (e) {
  //       if (fileElem) {
  //         fileElem.click();
  //         // setFormData()
  //       }
  //     },
  //     false
  //   );
  // };
  // const ImageUpload1 = (e) => {
  //   const fileSelect = document.getElementById("fileSelect1"),
  //     fileElem = document.getElementById("fileElem1");
  //   fileSelect.addEventListener(
  //     "click",
  //     function (e) {
  //       if (fileElem) {
  //         fileElem.click();
  //         // setFormData()
  //       }
  //     },
  //     false
  //   );
  // };
  // const ImageUpload2 = (e) => {
  //   const fileSelect = document.getElementById("fileSelect2"),
  //     fileElem = document.getElementById("fileElem2");
  //   fileSelect.addEventListener(
  //     "click",
  //     function (e) {
  //       if (fileElem) {
  //         fileElem.click();
  //         // setFormData()
  //       }
  //     },
  //     false
  //   );
  // };
  // const ImageUpload3 = (e) => {
  //   const fileSelect = document.getElementById("fileSelect3"),
  //     fileElem = document.getElementById("fileElem3");
  //   fileSelect.addEventListener(
  //     "click",
  //     function (e) {
  //       if (fileElem) {
  //         fileElem.click();
  //         // setFormData()
  //       }
  //     },
  //     false
  //   );
  // };
  // const ImageUpload4 = (e) => {
  //   const fileSelect = document.getElementById("fileSelect4"),
  //     fileElem = document.getElementById("fileElem4");
  //   fileSelect.addEventListener(
  //     "click",
  //     function (e) {
  //       if (fileElem) {
  //         fileElem.click();
  //         // setFormData()
  //       }
  //     },
  //     false
  //   );
  // };
  // const ImageUpload5 = (e) => {
  //   const fileSelect = document.getElementById("fileSelect5"),
  //     fileElem = document.getElementById("fileElem5");
  //   fileSelect.addEventListener(
  //     "click",
  //     function (e) {
  //       if (fileElem) {
  //         fileElem.click();
  //         // setFormData()
  //       }
  //     },
  //     false
  //   );
  // };
  // const ImageUpload6 = (e) => {
  //   const fileSelect = document.getElementById("fileSelect6"),
  //     fileElem = document.getElementById("fileElem6");
  //   fileSelect.addEventListener(
  //     "click",
  //     function (e) {
  //       if (fileElem) {
  //         fileElem.click();
  //         // setFormData()
  //       }
  //     },
  //     false
  //   );
  // };

  const handleSubmit = async () => {
    try {
      const formSubmit = await axios
        .post("https://app.backend.taskmo.co/hr/add_asm", formData)
        .then((res) => {
          console.log(res, "Response add asm");
          if (res.data.error) {
            Swal.fire({
              icon: "error",
              title: `${res.data.message}`,
              // timer:700,
              showConfirmButton: true,
              showCancelButton: false,
            });
          } else {
            console.log(res.data, "successed in uploading data");
            Swal.fire({
              icon: "success",
              title: "Submitted",
              timer: 700,
              showConfirmButton: false,
              showCancelButton: false,
            }).then(() => {
              window.location = `${BASE_URL}/dash`;
            });
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: `error ${err}`,
            // timer:700,
            showConfirmButton: true,
            // showCancelButton:false
          });
        });
    } catch (err) {
      console.log(err);
    }
  };

  console.log(formData, "form data total");

  //https://app.backend.taskmo.co/cm_images/image-1629875185305.svg
  return (
    <div className="addcm-container">
      <div className="container-fluid">
        <div className="row">
          <div className="col mt-2">
            <p className="heading-city-manager">Add ASM Details &#x3A;</p>
          </div>
        </div>
        <div className="row">
          {/* <div className="col-2 mt-5">
            <input
              type="file"
              id="fileElem6"
              accept="image/*"
              style={{ display: "none" }}
              name="profile_image"
              value={formData.profileImage}
              onChange={(value) => {
                uploadImage(value);
              }}
            />
            <div
              className="documents-box"
              id="fileSelect6"
              onClick={ImageUpload6}
            >
              <div className="pic-container">
                <img
                  src={
                    !formData.profile_image
                      ? window.location.origin + "/images/profile_image.svg"
                      : formData.profile_image
                  }
                  alt="aadharfront"
                  className="image_upload_api"
                />
              </div>
            </div>
          </div> */}
          <div className="col-12 border-form">
            <div className="form-container">
              <form className="form-details">
                <div className="col-6 input-field">
                  <label className="cm_label" htmlFor="Name">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control property-box"
                    placeholder="Name"
                    onChange={(e) => {
                      formData.full_name = e.target.value.trim();
                      setFormData({ ...formData });
                    }}
                  />
                </div>
                <div className="col-6 input-field">
                  <label className="cm_label" htmlFor="Contact number">
                    Contact number
                  </label>
                  <input
                    type="text"
                    className="form-control property-box"
                    placeholder="Contact number"
                    onChange={(e) => {
                      if (e.target.value.trim().length === 10) {
                        formData.mobile_number = e.target.value.trim();
                        setFormData({ ...formData });
                      } else if (e.target.value.trim().length > 10) {
                        e.target.value = e.target.value.substr(0, 10);
                      }
                    }}
                  />
                </div>
                {/* ssecond input row */}
                <div className="col-6 input-field">
                  <label className="cm_label" htmlFor="Email ID">
                    Email ID
                  </label>
                  <input
                    type="text"
                    className="form-control property-box"
                    placeholder="Email ID"
                    onChange={(e) => {
                      if (ValidateEmail(e.target.value.trim())) {
                        formData.email_id = e.target.value.trim();
                        setFormData({ ...formData });
                      }
                    }}
                  />
                </div>
                {/* <div className="col-6 input-field">
                  <select
                    className="form-control property-box"
                    // placeholder="State"
                    onChange={(e) => {
                      formData.state = e.target.value;
                      setFormData({ ...formData });
                    }}
                  >
                    <option
                      className="selectPlaceholder"
                      value=""
                      disabled
                      selected
                      hidden
                    >
                      Choose your State
                    </option>
                    {sortedStates.map((el, idx) => {
                      return (
                        <option key={idx} value={el}>
                          {el}
                        </option>
                      );
                    })}
                  </select>
                </div> */}
                {/* third input row */}
                {/* <div className="row mt-3">
                <div className="col-6 input-field">
                  <select
                    className="form-control property-box"
                    placeholder="City"
                    onChange={(e)=>{
                      formData.city = e.target.value;
                      setFormData({ ...formData });
                    }}
                  >
                    <option
                      className="selectPlaceholder"
                      value=""
                      disabled
                      selected
                      hidden
                    >
                      Choose your City
                    </option>
                    {formData.state.trim().length !== 0
                      ? stateCity
                          .filter(({ state: s }) => {
                            return formData.state === s;
                          })
                          .map(({ name: city }, idx) => {
                            return (
                              <option key={idx} value={city}>
                                {city}
                              </option>
                            );
                          })
                      : null}
                  </select>
                </div>
                <div className="col-6 input-field">
                  <input
                    type="text"
                    className="form-control property-box"
                    placeholder="Pincode"
                    onChange={(e) => {
                      formData.pincode = e.target.value;
                      setFormData({ ...formData });
                    }}
                  />
                </div>
              </div> */}
                {/* fourth input row */}
                {/* <div className="row mt-3"> */}
                {/* <div className="col-6 input-field">
                  <input
                    type="text"
                    className="form-control property-box"
                    placeholder="Date of onboarding (dd/mm/yy)"
                    onChange={(e) => {
                      formData.dateOfOnboard = e.target.value.trim();
                      setFormData({ ...formData });
                    }}
                  /> */}
                {/* <ReactDatePicker/> */}
                {/* <DatePicker selected={formData.dateOfOnboard} onChange={(date) => {
                      formData.dateOfOnboard=date;
                      setFormData({...formData})
                  }} /> */}
                {/* </div> */}
                {/* <div className="col-6 input-field">
                  <input
                    type="text"
                    className="form-control property-box"
                    placeholder="Date of Joining (yyyy-mm-dd)"
                    onChange={(e) => {
                      formData.doj = e.target.value.trim();
                      setFormData({ ...formData });
                    }}
                  />
                </div> */}
                {/* </div> */}
                {/* fifth inpu row */}
                <div className="col-6 input-field">
                  <label className="cm_label" htmlFor="Aadhar Number">
                    Aadhar Number
                  </label>
                  <input
                    type="text"
                    className="form-control property-box"
                    maxLength="12"
                    placeholder="Aadhar Number"
                    onChange={(e) => {
                      formData.aadhar_number = e.target.value.trim();
                      setFormData({ ...formData });
                    }}
                  />
                </div>
                <div className="col-6 input-field">
                  <label className="cm_label" htmlFor="Aadhar Number">
                    Type
                  </label>
                  {/* <input
                    type="text"
                    className="form-control property-box"
                    placeholder="Type"
                    onChange={(e) => {
                      formData.pan_number = e.target.value.trim();
                      setFormData({ ...formData });
                    }}
                  /> */}
                  <select
                    className={`mySelect form-control property-box`}
                    onChange={(e) => {
                      formData.type = e.target.value.trim();
                      setFormData({ ...formData });
                    }}
                    required
                  >
                    <option className="purple_text_selector" hidden="true">
                      select type
                    </option>
                    <option value="1">Sr. ASM</option>
                    <option value="2">ASM</option>
                    <option value="3">TSM</option>
                  </select>
                </div>
                {/* Sixth input row */}
                {/* <div className="row mt-3">
                <div className="col-6 input-field">
                  <input
                    list="datalistOptions"
                    id="exampleDataList"
                    type="text"
                    className="form-control property-box"
                    placeholder="City manager"
                    onChange={(e) => {
                      if (e.target.value) {
                        const [id, cm_name] = e.target.value.split(",");
                        formData.cm_id = id;
                        setFormData({ ...formData });
                      }
                      setTimeout(() => {
                        console.log(formData);
                      }, 500);
                    }}
                  />
                  <datalist id="datalistOptions">
                    {cmList.map((obj, idx) => {
                      return (
                        <option value={obj.cm_id + "," + obj.cm_name}></option>
                      );
                    })}
                    
                  </datalist>
                </div>
              </div>
             */}
              </form>
            </div>
            {/* Uploading Images */}
            {/* <div className="row mt-3 border-form mt-5">
              <div className="border-box1">
                <p className="need-opacity mt-3">Aadhar Images</p>

                <div>
                  <input
                    type="file"
                    id="fileElem"
                    accept="image/*"
                    style={{ display: "none" }}
                    name="aadhar_front_image"
                    value={formData.aadharFrontImage}
                    onChange={(value) => {
                      uploadImage(value);
                    }}
                  />

                  <div
                    className="documents-box"
                    id="fileSelect"
                    onClick={ImageUpload}
                  >
                    <p>Aadhar front image</p>
                    <div className={`myRowCenter`}>
                      <img
                        src={
                          !formData.aadhar_front_image
                            ? window.location.origin +
                              "/images/Upload_image.svg"
                            : formData.aadhar_front_image
                        }
                        alt="aadharfront"
                        className="image_upload_api"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <input
                    type="file"
                    id="fileElem1"
                    accept="image/*"
                    style={{ display: "none" }}
                    name="aadhar_back_image"
                    value={formData.aadharBackImage}
                    onChange={(value) => {
                      uploadImage(value);
                    }}
                  />

                  <div
                    className="documents-box"
                    id="fileSelect1"
                    onClick={ImageUpload1}
                  >
                    <p>Aadhar back image</p>
                    <div className={`myRowCenter `}>
                      <img
                        src={
                          !formData.aadhar_back_image
                            ? window.location.origin +
                              "/images/Upload_image.svg"
                            : formData.aadhar_back_image
                        }
                        alt="aadharfront"
                        className="image_upload_api"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div className="row mt-3 border-form mt-5">
              <div className="border-box1">
                <p className="need-opacity mt-3">DL Images</p>
                <div className="documents-box">
                  <div>
                    <input
                      type="file"
                      id="fileElem2"
                      accept="image/*"
                      style={{ display: "none" }}
                      name="dl_front_image"
                      value={formData.dlFrontImage}
                      onChange={(value) => {
                        uploadImage(value);
                      }}
                    />

                    <div
                      className="documents-box"
                      id="fileSelect2"
                      onClick={ImageUpload2}
                    >
                      <p>DL front image</p>
                      <div className={`myRowCenter `}>
                        <img
                          src={
                            !formData.dl_front_image
                              ? window.location.origin +
                                "/images/Upload_image.svg"
                              : formData.dl_front_image
                          }
                          alt="aadharfront"
                          className="image_upload_api"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <input
                      type="file"
                      id="fileElem3"
                      accept="image/*"
                      style={{ display: "none" }}
                      name="dl_back_image"
                      value={formData.dlBackImage}
                      onChange={(value) => {
                        uploadImage(value);
                      }}
                    />

                    <div
                      className="documents-box"
                      id="fileSelect3"
                      onClick={ImageUpload3}
                    >
                      <p>DL back image</p>
                      <div className={`myRowCenter `}>
                        <img
                          src={
                            !formData.dl_back_image
                              ? window.location.origin +
                                "/images/Upload_image.svg"
                              : formData.dl_back_image
                          }
                          alt="aadharfront"
                          className="image_upload_api"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div className="row mt-3 border-form mt-5">
              <div className="border-box1">
                <p className="need-opacity mt-3">Bank Statement And PAN</p>
                <div className="documents-box">
                  <div>
                    <input
                      type="file"
                      id="fileElem4"
                      accept="image/*"
                      style={{ display: "none" }}
                      name="bank_statement_image"
                      value={formData.bankImage}
                      onChange={(value) => {
                        uploadImage(value);
                      }}
                    />

                    <div
                      className="documents-box"
                      id="fileSelect4"
                      onClick={ImageUpload4}
                    >
                      <p>Bank Statement</p>
                      <div className={`myRowCenter `}>
                        <img
                          src={
                            !formData.bank_statement_image
                              ? window.location.origin +
                                "/images/Upload_image.svg"
                              : formData.bank_statement_image
                          }
                          alt="aadharfront"
                          className="image_upload_api"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <input
                      type="file"
                      id="fileElem5"
                      accept="image/*"
                      style={{ display: "none" }}
                      name="pan_image"
                      value={formData.panImage}
                      onChange={(value) => {
                        uploadImage(value);
                      }}
                    />

                    <div
                      className="documents-box"
                      id="fileSelect5"
                      onClick={ImageUpload5}
                    >
                      <p>PAN</p>
                      <div className={`myRowCenter `}>
                        <img
                          src={
                            !formData.pan_image
                              ? window.location.origin +
                                "/images/Upload_image.svg"
                              : formData.pan_image
                          }
                          alt="aadharfront"
                          className="image_upload_api"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="form_submit_button mt-3">
              <button onClick={handleSubmit}>Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddAsm;
