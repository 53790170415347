import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./Dash.css";
import axios from "axios";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import { startLogin } from "./action/loggedin";
import { startGetProjectList } from "./action/projectList";
import { startGetMyStats } from "./action/myStats";
import Table from "./STable/Table";
import AsmList from "./AsmList/AsmList";
import { Route } from "react-router-dom";
import TlList from "./TlList/TlList";
import FseList from "./FseList/FseList";
import { mainStats, MAIN_STATS_URL } from "../utils";
import Swal from "sweetalert2";
import AddCm from "./AddCm/AddCm";
import AsmProfile from "./AsmProfile/AsmProfile";
import CmProfile from "./CmProfile/CmProfile";
import AddAsm from "./AddAsm/AddAsm";
import { LinearProgress } from "@material-ui/core";
import SideBar from "./SideBar/SideBar";
import ProjectList from "./ProjectList/ProjectList";
import MyStats from "./MyStats/MyStats";
import MyTeam from "./MyTeam/MyTeam";
import TsmStats from "./tsmStats/TsmStats";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { motion } from "framer-motion";
import ProjectListUnique from "./ProjectListUnique/ProjectListUnique";
function Dash(props) {
  const [phoneNo, setPhoneNo] = useState("");
  const [fseOrTsm, setFseOrTsm] = useState("asm");
  const [loading, setLoading] = useState(false);
  const [mainStats, setMainStats] = useState({});
  const [cmId, setCmId] = useState(-1);
  const [cmStats, setCmStats] = useState({});
  const [activeCm, setActiveCm] = useState(true);
  const [inactiveCm, setInactiveCm] = useState(false);
  const history = useHistory();
  const logoutButton = () => {
    localStorage.removeItem("hr_token");
    localStorage.removeItem("hr_email");
    props.dispatch(startLogin(false));
  };
  let fetchSearchData = () => {
    axios
      .get(
        `https://nodeapi.taskmo.com/v1/hr/tree?mobile_num=${phoneNo}&user_type=${fseOrTsm}`
      )
      .then((r) => {
        console.log(r.data.data);
        if (r.data.data && r.data.data.length > 0) {
          Swal.fire({
            icon: "success",
            html: `<div>City Manager: ${r.data.data[0].cm_name}<br/> City Manager Number: ${r.data.data[0].cm_number}<br/> ASM Name:${r.data.data[0].asm_name}<br/> ASM Number:${r.data.data[0].asm_number}</div>`,
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "Oops!Something went wrong.",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Unable to fetch! Server error",
        });
      });
  };
  // localStorage.removeItem("activeCmId");
  // const handleView = (e) => {
  //   props.dispatch(startGetMyStats(e));
  //   // console.log(id,"button view")
  //   // console.log(e,"button view")
  // };
  // useEffect(() => {
  //   props.dispatch(startGetProjectList("4"));
  // }, []);
  useEffect(() => {
    const asyncFunc = async () => {
      const res1 = await fetch(MAIN_STATS_URL, {
        method: "POST",
      });
      const res = await res1.json();
      console.log("main stats response HR", res);
      if (res.error) {
        Swal.fire({
          icon: "error",
          text: "Unable to fetch main stats",
        });
      } else {
        setMainStats({ ...res });
      }
    };
    asyncFunc();
  }, []);

  console.log(phoneNo, "proj_list");
  return (
    <div className="dashboard">
      <div className="dashboard_col2">
        <div className="makeFixed">
          <div className="header-material">
            <div className="dash_header_col2">
              <p className="dash_header_p1">Welcome back,</p>
              <h1 className="dash_head_name">
                {JSON.parse(localStorage.getItem("profileObj")).givenName}
              </h1>
            </div>
            <div className="dash_header_col1">
              <Link to="/login">
                <div className="logout_box" onClick={() => logoutButton()}>
                  <img
                    src={
                      window.location.origin + "/images/logo/logout_black.svg"
                    }
                    alt="ott"
                    className="logout_ott"
                  />
                  Logout
                </div>
              </Link>
            </div>
          </div>
          <div>{loading ? <LinearProgress /> : null}</div>

          {/* <img
            src={window.location.origin + "/images/profile image.svg"}
            alt="profile"
            className="dash_profile"
          /> */}
        </div>

        <div style={{ display: "flex", minHeight: "60vh" }}>
          <div style={{ marginTop: "52px" }}>
            <SideBar />
          </div>
          <div className="main-body">
            <Route exact path="/dash">
              <div className="p1-container">
                {/* <div className="p1-select-tag-container">
              <select className="p1-select-tag">
                <option>This Month</option>
                <option>Today</option>
              </select>
            </div> */}
                <div className="stats-card-container">
                  <div className="my-card light-green">
                    <div className="my_card_p1">Total Leads</div>
                    <div className={`monthly`}>
                      {Object.keys(mainStats).length > 2
                        ? mainStats.total_leads
                        : "XXXX"}
                    </div>
                    <div className="my_card padding_card_p1">
                      +
                      {Object.keys(mainStats).length > 2
                        ? mainStats.total_leads_today
                        : "XXXX"}
                    </div>
                  </div>
                  <div className="my-card light-orange">
                    <div className="my_card_p1">Approved Leads</div>
                    <div className={`monthly`}>
                      {Object.keys(mainStats).length > 2
                        ? mainStats.total_approved
                        : "XXXX"}
                    </div>

                    <div className="my_card padding_card_p2">
                      +
                      {Object.keys(mainStats).length > 2
                        ? mainStats.total_approved_today
                        : "XXXX"}
                    </div>
                  </div>
                  <div className="my-card light-brown">
                    <div className="my_card_p1"> Total Checkins</div>
                    <div className={`monthly`}>
                      {Object.keys(mainStats).length > 2
                        ? mainStats.total_checkin
                        : "XXXX"}
                    </div>
                    <div className="my_card padding_card_p3">
                      +
                      {Object.keys(mainStats).length > 2
                        ? mainStats.total_checkin_today
                        : "XXXX"}
                    </div>
                  </div>
                  <div className="my-card cream">
                    <div className="my_card_p1">Total Onboarding</div>
                    <div className={`monthly`}>
                      {Object.keys(mainStats).length > 2
                        ? mainStats.total_onboarding
                        : "XXXX"}
                    </div>
                    <div className="my_card padding_card_p4">
                      +
                      {Object.keys(mainStats).length > 2
                        ? mainStats.total_onboarding_today
                        : "XXXX"}
                    </div>
                  </div>
                </div>
                <div className="addCm-btn">
                  <div className="city_manager_name">City Manager</div>
                  <div className="myRow">
                    <div className="search-container">
                      <input
                        onChange={(e) => {
                          setPhoneNo(e.target.value);
                        }}
                        onKeyUp={(e) => {
                          if (e.keyCode == 13) {
                            fetchSearchData();
                          }
                        }}
                        className="search-input"
                        placeholder="Enter Number"
                      />
                      {/* {console.log(phoneNo, "this is change")} */}
                      {console.log(phoneNo.length, "this is length")}

                      <select
                        onChange={(e) => {
                          setFseOrTsm(e.target.value);
                        }}
                        className="filter-option"
                      >
                        <option value="asm">TSM</option>
                        <option value="fse">FSE</option>
                      </select>
                      {/* {console.log(fseOrTsm)} */}
                      {phoneNo.length !== 10 ? (
                        ""
                      ) : (
                        <motion.span
                          initial={{ opacity: 0 }}
                          animate={{ scale: 1.5, opacity: 1 }}
                          transition={{ duration: 0.5 }}
                        >
                          <ArrowForwardIcon
                            onClick={fetchSearchData}
                            sx={{ ml: 2 }}
                            variant="outlined"
                          ></ArrowForwardIcon>
                        </motion.span>
                      )}
                    </div>
                    <select
                      className="nav-tab"
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value === "active") {
                          setActiveCm(true);
                          setInactiveCm(false);
                        } else if (value === "inactive") {
                          setInactiveCm(true);
                          setActiveCm(false);
                        } else {
                          setActiveCm(false);
                          setInactiveCm(false);
                        }
                      }}
                    >
                      <option value="active">ACTIVE</option>
                      <option value="inactive">INACTIVE</option>
                      <option value="all">ALL</option>
                    </select>

                    {/* <div
                      className={`nav-tab ${activeCm ? "active" : ""}`}
                      onClick={() => {
                        setActiveCm(true);
                        setInactiveCm(false);
                      }}
                    >
                      ACTIVE
                    </div> */}
                    {/* <div
                      className={`nav-tab ${inactiveCm ? "active" : ""}`}
                      onClick={() => {
                        setInactiveCm(true);
                        setActiveCm(false);
                      }}
                    >
                      INACTIVE
                    </div> */}
                    {/* <div
                      className={`nav-tab ${
                        !activeCm && !inactiveCm ? "active" : ""
                      }`}
                      onClick={() => {
                        setInactiveCm(false);
                        setActiveCm(false);
                      }}
                    >
                      ALL
                    </div> */}
                    {localStorage.getItem("is_active") === "active" ? (
                      <div
                        className="button_add"
                        onClick={() => {
                          history.push("/dash/addCm");
                        }}
                      >
                        <img
                          src={window.location.origin + "/images/svg1/add.svg"}
                          alt="ott"
                          className="add_cm_image"
                        />
                        Add City Manager
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                {/* Table */}
                <div className="p1-table-container">
                  <Table
                    setCmId={setCmId}
                    setCmStats={setCmStats}
                    loading={loading}
                    setLoading={setLoading}
                    activeCm={activeCm}
                    inactiveCm={inactiveCm}
                  />
                </div>
              </div>
            </Route>
            <Route path="/dash/asmList/:cmIdFromParam">
              <AsmList
                cmId={cmId}
                cmStats={cmStats}
                loading={loading}
                setLoading={setLoading}
              />
            </Route>
            <Route path="/dash/:asmId/fseList">
              <FseList loading={loading} setLoading={setLoading} />
            </Route>
            <Route path="/dash/addCm">
              <AddCm loading={loading} setLoading={setLoading} />
            </Route>
            <Route path="/dash/addAsm/:cmIdForAddAsm">
              <AddAsm loading={loading} setLoading={setLoading} />
            </Route>
            <Route path="/dash/asmProfile/:asmIdForVp/">
              <AsmProfile loading={loading} setLoading={setLoading} />
            </Route>
            <Route path="/dash/cmProfile/:cmIdForVp">
              <CmProfile loading={loading} setLoading={setLoading} />
            </Route>
            <Route path="/dash/projectList">
              <ProjectListUnique />
            </Route>
            <Route path="/dash/sowList">
              <ProjectList />
            </Route>
            <Route path="/dash/myStats">
              <MyStats />
            </Route>
            <Route path="/dash/myTeam">
              <MyTeam />
            </Route>
            <Route path="/dash/tsm_Stats">
              <TsmStats />
            </Route>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    projectList: state.projectList,
  };
};

export default connect(mapStateToProps)(Dash);
