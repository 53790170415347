import axios from "axios";
import Swal from "sweetalert2";
import { ASM_LIST_TO_REMAP_URL, FSE_REMAP_URL, MARK_ACTIVE_URL, MARK_INACTIVE_URL } from "../../utils";

const updateOnboardStatus = (
  fse_user_id,
  hr_admin_id,
  comment,
  action_type
) => {
  const url = MARK_INACTIVE_URL;
  const body = {
    fse_user_id,
    hr_admin_id: hr_admin_id,
    comment: `${comment}`,
    action_type: `${action_type}`,
  };
  axios
    .put(url, body)
    .then((r) => {
      if (r.data.error) {
      } else {
        console.log(r,"marked inactive backend response");
          Swal.fire({
              icon:'success',
              title:"Marked Inactive"
          })
      }
    })
    .catch((e) => {
      Swal.fire({
        icon: "error",
        text: JSON.stringify({
          e,
        }),
      });
    });
};
const markFseActiveApiCall = (
  fse_user_id,
  hr_admin_id,
  comment,
  action_type
) => {
  const url = MARK_ACTIVE_URL;
  const body = {
    fse_user_id,
    hr_admin_id: hr_admin_id,
    comment: `${comment}`,
    action_type: `${action_type}`,
  };
  axios
    .put(url, body)
    .then((r) => {
      if (r.data.error) {
      } else {
        console.log(r,"marked inactive backend response");
          Swal.fire({
              icon:'success',
              title:"Marked Active"
          })
      }
    })
    .catch((e) => {
      Swal.fire({
        icon: "error",
        text: JSON.stringify({
          e,
        }),
      });
    });
};
const remapFse=(fse_id,asm_id,hr_admin_id)=>{
  return new Promise((resolve,reject)=>{
    fetch(FSE_REMAP_URL,{
      method:'PUT',
      headers:{
        'content-type':'application/json'
      },
      body:JSON.stringify({
        fse_id,asm_id,hr_admin_id
      })
    }).then(r=>r.json()).then((r)=>{
      resolve(r);
    }).catch((e)=>{
      reject(e);
    })
  })
}

const getAsmList=()=>{
  return new Promise((resolve,reject)=>{
    fetch(ASM_LIST_TO_REMAP_URL).then(r=>r.json()).then((r)=>{
      console.log(r,"asmList for remaping fse");
      resolve(r);
    }).catch((err)=>{
      console.error(err,"<- error while fetching asm list")
      reject(err);
    })
  })
}
export { updateOnboardStatus,markFseActiveApiCall,remapFse,getAsmList };
