import { PROJECT_WISE_ASMS_URL, PROJECT_WISE_LEADS_URL } from "../../utils";

export const getLeadCount = () => {
  return new Promise((resolve, reject) => {
    fetch(PROJECT_WISE_LEADS_URL)
      .then((r) => r.json())
      .then((r) => {
        console.log(r, "getLeadCount Project wise");
        if (r.error) {
          reject(r);
        } else {
          resolve(r);
        }
      })
      .catch((err) => {
        console.error(err, " Error: getLeadCount Project Wise");
        reject(err);
      });
  });
};

export const getAsmCount=()=>{
  return new Promise((resolve,reject)=>{
    fetch(PROJECT_WISE_ASMS_URL).then(r=>r.json()).then((r)=>{
      console.log(r, "getAsmCount() Project wise");
      if(r.error){
        reject(r);
      }else{
        resolve(r);
      }
    }).catch((err)=>{
      console.error(err, " Error: getAsmCount() Project Wise");
      reject(err);
    })
  })
}
