import { MARK_CM_ACTIVE_URL, MARK_CM_INACTIVE_URL } from "../../utils"

export const markCmInactiveApi=(cm_id)=>{
    return new Promise((resolve,reject)=>{
        fetch(MARK_CM_INACTIVE_URL,{
            method:"PUT",
            headers:{
                'content-type':'application/json'
            },
            body:JSON.stringify({
                cm_id,
                hr_admin_id:localStorage.getItem('hr_admin_id'),
                comment:"marking cm_id inactive:"+cm_id,
                action_type:'inactive'
            })
        }).then(r=>r.json()).then((r)=>{
            resolve(r);
        }).catch((err)=>{
            reject(err)
        })
    })
}
export const markCmActiveApi=(cm_id)=>{
    return new Promise((resolve,reject)=>{
        fetch(MARK_CM_ACTIVE_URL,{
            method:"PUT",
            headers:{
                'content-type':'application/json'
            },
            body:JSON.stringify({
                cm_id,
                hr_admin_id:localStorage.getItem('hr_admin_id'),
                comment:"marking cm_id active:"+cm_id,
                action_type:'active'
            })
        }).then(r=>r.json()).then((r)=>{
            resolve(r);
        }).catch((err)=>{
            reject(err)
        })
    })
}