import React, { useState, useEffect, useContext } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { table_data } from "./TableData";
import "./Table.css";
// import StatusBar from "../StatusBar/StatusBar";
import TripleDotDropDown from "../TripleDotDropDown";
import {
  CM_LIST_URL,
  CM_WISE_THIS_MONTH_CHECKINS,
  CM_WISE_THIS_MONTH_LEADS,
  CM_WISE_TODAY_CHECKINS,
  CM_WISE_TODAY_LEADS,
} from "../../utils";
import Swal from "sweetalert2";
import DataTableExtensions from "react-data-table-component-extensions";
import { CmContext } from "../../App";
createTheme("solarized", {
  text: {
    primary: "black",
    secondary: "#b2b2b2",
  },
  background: {
    default: "#f2f2f2",
  },
  boxshadow: {
    default: "5px 5px 30px #DEDEDEBF",
  },
  context: {
    background: "#cb4b16",
    text: "#FFFFFF",
  },
  divider: {
    default: "#fafafa",
  },
  action: {
    button: "rgba(0,0,0,.54)",
    hover: "rgba(0,0,0,.08)",
    disabled: "rgba(0,0,0,.12)",
  },
});

function Table({ api, admin, setCmId, setCmStats, setLoading,activeCm,inactiveCm }) {
  const [cmListApi, setCmListApi] = useState();
  const [context,setContext]=useContext(CmContext);
  const columns = [
    {
      name: "",
      sortable: true,
      center: true,
      cell: (d) => (
        <div className="cm-pic-container">
          <div style={{ margin: "10px" }}>
            <img
              src={`${d.profile_image}`}
              className="cm-image"
              onError={(e) => {
                e.target.src =
                  window.location.origin + "/images/noun_profile.svg";
              }}
            />
          </div>
          <div style={{ textAlign: "center" }}>{d.name}</div>
          <div className={`dateStyle`}>{d.date.substr(0, 10)}</div>
        </div>
      ),
    },
    {
      name: "",
      center: true,
      cell: (d) => (
        <div className="myCol">
          <div>City</div>
          <div className="cmCity">{d.city}</div>
        </div>
      ),
    },
    {
      name: "",
      cell: (d) => (
        <div className="myCol">
          <div className="colTitle">No. Of Active</div>
          <div className="colValue">{`${d.active_asm}:${
            d.active_fse ? d.active_fse : 0
          }`}</div>
          <div className="col_subtitle">ASM : FSE</div>
        </div>
      ),
      center: true,
    },
    {
      name: "",
      cell: (d) => (
        <div className="myCol">
          <div className="colTitle">Today Checkins</div>
          {/* <div className="col_value2">{`${typeof d.total_checkin ==='undefined'?"0":d.total_checkin}`}</div> */}
          <div className="col_value2">
            {typeof d.today_checkin === "undefined" ? "0" : d.today_checkin}
          </div>
        </div>
      ),
      center: true,
    },
    {
      name: "",
      cell: (d) => (
        <div className="myCol">
          <div className="colTitle">Today Leads</div>
          <div className="col_value2">
            {typeof d.today_leads === "undefined" ? "+0" : "+"+d.today_leads}
          </div>
          <div className="col_value3">
            {`${typeof d.total_leads === "undefined" ? "0" : d.total_leads + ""}`}{" "}
            
          </div>
          {/* <div className="col_value2">{`${d.total_leads}`}</div>
          <div className="col_value3">+{d.today_leads} Today</div> */}
        </div>
      ),
      center: true,
      // cell: d => <div>{<StatusBar o1={d.ride1_date} o2={d.ride2_date} o3={d.ride3_date} o4={d.ride4_date} o5={d.ride5_date}/>}</div>
    },
    {
      name: "",
      cell: (d) => (
        <div className="myCol">
          <div className="colTitle">Today Approved</div>
          {/* <div className="col_value2">{`${d.total_approved}`}</div>
          <div className="col_value3">+{d.today_approved} Today</div> */}
          
          <div className="col_value2">
            {typeof d.today_approved === "undefined" ? "+0" : "+"+d.today_approved}{" "}
            
          </div>
          <div className="col_value3">{`${
            typeof d.total_approved === "undefined" ? "0" : d.total_approved+""
          } `}</div>
          
        </div>
      ),
      center: true,
    },
    {
      name: "",
      cell: (d) => (
        <div className="myCol">
          <div className="colTitle">Status</div>
          {/* <div>{`Status`}</div> */}
          <div
            style={{ textTransform: "uppercase" }}
            className={`onBoardStatus ${
              d.status === "inactive" ? "inactive" : ``
            } ${d.status === "hold" ? "hold" : ``} ${
              d.status === "onboarded" ? "active" : ``
            }  ${d.status === "none" ? "hold" : ``} fixStatusCard`}
            style={{ textAlign: "center" }}
          >
            {d.status}
          </div>
        </div>
      ),
      center: true,
    },
    {
      name: "",
      cell: (d) => (
        <TripleDotDropDown
          admin={admin}
          cm_id={d.cm_id}
          setCmId={setCmId}
          setCmStats={setCmStats}
          data1={d}
          activeCm={activeCm}
          inactiveCm={inactiveCm}
        />
      ),
      right: true,
      width: "100px",
    },
  ];
  // if (admin) {
  //   columns.splice(2, 0, {
  //     name: "FSE Id",
  //     cell: (d) => <div>{d.user_id}</div>,
  //     sortable: true,
  //     center: true,
  //   });
  // }

  useEffect(() => {
    if (localStorage.getItem("cmListApi")) {
      setCmListApi([
        ...JSON.parse(localStorage.getItem("cmListApi")).lCmListApi,
      ]);
    }
    const asyncFunc = async () => {
      setLoading(true);
      const res1 = await fetch(CM_LIST_URL, {
        method: "GET",
      });
      const res = await res1.json();
      console.log("main stats response HR", res);
      if (res.error) {
        Swal.fire({
          icon: "error",
          text: "Unable to fetch CM List",
        });
        setLoading(false);
      } else {
        // const {
        //   cm_id,
        //   full_name: name,
        //   mobile_number,
        //   profile_image,
        //   city,
        //   member_since,
        //   onboarded_by,
        //   total_asm_count: active_asm,
        //   total_leads,
        //   total_leads_today: today_leads,
        //   total_checkin,
        //   total_checkin_today: today_checkin,
        //   total_approved,
        //   total_approved_today: today_approved,
        //   onboard_status: status,
        // } = res.cm_list;
        context.cmList=res.cm_list;
        setContext({...context});
        setTimeout(()=>{
          console.log(context,"<- remap asm check cm list under table.js");
        })
        const newCmArr = res.cm_list.map(
          ({
            cm_id,
            full_name: name,
            mobile_number,
            profile_image,
            city,
            member_since,
            onboarded_by,
            total_asm_count: active_asm,
            total_leads,
            total_leads_today: today_leads,
            total_checkin,
            total_checkin_today: today_checkin,
            total_approved,
            total_approved_today: today_approved,
            onboard_status: status,
            total_fse,
          }) => {
            return {
              cm_id,
              name,
              date: member_since,
              city,
              active_asm,
              active_fse: total_fse,
              total_checkin,
              today_checkin,
              total_leads,
              today_leads,
              total_approved,
              today_approved,
              status,
              profile_image,
            };
          }
        );
        setCmListApi([...newCmArr]);
        fetch(CM_WISE_TODAY_CHECKINS)
          .then((r) => {
            if (r.ok) {
              return r.json();
            } else {
              throw Error(JSON.stringify({ ...r }));
            }
          })
          .then((r) => {
            console.log(r, "today cmlist checkins");
            const addedTodayCheckins = newCmArr
              .map((obj) => {
                r.data.every(({ cm_id, checkin_count }) => {
                  if (cm_id === obj.cm_id) {
                    obj.today_checkin = checkin_count;
                    return false;
                  }
                  return true;
                });
                return obj;
              })

            // console.log(addedTodayCheckins);
            setCmListApi([...addedTodayCheckins]);
            fetch(CM_WISE_TODAY_LEADS)
              .then((r) => {
                if (r.ok) {
                  return r.json();
                } else {
                  throw Error(JSON.stringify({ ...r }));
                }
              })
              .then((r) => {
                console.log(r, "today leads cm wise");
                const addedTodayLeads = addedTodayCheckins.map((obj) => {
                  r.data.every(({ cm_id, lead_count,total_approved }) => {
                    if (cm_id === obj.cm_id) {
                      obj.today_leads = lead_count;
                      obj.today_approved=total_approved;
                      return false;
                    }
                    return true;
                  });
                  return obj;
                });
                console.log(addedTodayLeads, "today leads xxxx");
                setCmListApi([...addedTodayLeads]);

                fetch(CM_WISE_THIS_MONTH_LEADS)
                  .then((r) => {
                    if (r.ok) {
                      return r.json();
                    } else {
                      throw Error(JSON.stringify({ ...r }));
                    }
                  })
                  .then((r) => {
                    console.log(r, "this month leads and approved cm wise");
                    const addedThisMonthLeads = addedTodayLeads.map((obj) => {
                      r.data.every(({ cm_id, lead_count,total_approved }) => {
                        if (cm_id === obj.cm_id) {
                          obj.total_leads = lead_count;
                          obj.total_approved=total_approved;
                          return false;
                        }
                        return true;
                      });
                      return obj;
                    });
                    console.log(addedThisMonthLeads, "this month leads xxxx");
                    setCmListApi([...addedThisMonthLeads]);
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      test: "this month leads cmlist count"+error,
                    });
                  });
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  test: "this month leads cmlist count"+error,
                });
              });
            // fetch(CM_WISE_THIS_MONTH_CHECKINS)
            // .then((r)=>{
            //   if(r.ok){
            //     return r.json();
            //   }else{
            //     throw Error(r);
            //   }
            // })
            // .then((r)=>{
            //   console.log(r,"this month cmList checkins");
            //   const addedThismonthCheckins=newCmArr.map((obj)=>{

            //     r.data.every(({cm_id,checkin_count})=>{
            //       if(cm_id===obj.cm_id){
            //         obj.today_checkin=checkin_count;
            //         return false
            //       }
            //       return true;
            //     })
            //     return obj;
            //   })
            // })

            // Swal.fire({
            //   icon:"success",
            //   text:JSON.stringify({...r})
            // })
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              text: "today checkins cmlist"+error,
            });
          });
        localStorage.setItem(
          "cmListApi",
          JSON.stringify({
            lCmListApi: [...newCmArr],
          })
        );
        setLoading(false);
      }
    };
    asyncFunc();
  }, []);
  const tableData = {
    columns,
    data: cmListApi && cmListApi.filter((obj)=>{
      if(activeCm){
        return obj.status!=='inactive'
      }else if(inactiveCm){
        return obj.status==='inactive'
      }
      return true;
    }),
  };

  return (
    <div className="table_data">
      <div className="card_table">
        <div className="menu-container">
          {/* <TripleDotDropDown admin={admin} /> */}
        </div>
        <DataTableExtensions
          {...tableData}
          export={false}
          filterPlaceholder={`Search City Manager`}
        >
          <DataTable
            columns={tableData.columns}
            data={tableData.data}
            defaultSortFieldId={2}
            theme="solarized"
          />
        </DataTableExtensions>
      </div>
    </div>
  );
}

export default Table;
