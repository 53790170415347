import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TextField } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import { CmContext } from "../../App";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Mapteam({ open, setOpen }) {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // const [asmData, setAsmdata] = React.useState({});
  const [context, setContext] = React.useContext(CmContext);

  // React.useEffect(() => {
  //   const data = {
  //     asm_id: context.activeAsmId,
  //   };
  //   console.log(data,"inside mapteam")
  //   axios.post(TEAM_DATA_ASM, data).then((res) => {
  //     console.log(res.data,"cvbnmzxcfvgbhnjxcvb")
  //     setAsmdata(res.data.asm);
  //   }).catch((e)=>{
  //     console.log(e)
  //   })
  // }, []);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            View Team
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <span className="soi-form">
              <StyledEngineProvider injectFirst>
                <TextField
                  min={0}
                  id="outlined-number"
                  label="Main Team"
                  type="text"
                  disabled={true}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={context.mainTeam == "" ? "NA" : context.mainTeam}
                />
                <TextField
                  min={0}
                  id="outlined-number"
                  label="Sub Team"
                  type="text"
                  disabled={true}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={context.subTeam == "" ? "NA" : context.subTeam}
                />
                <TextField
                  min={0}
                  id="outlined-number"
                  label="Unit Team"
                  type="text"
                  disabled={true}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={context.unitTeam == "" ? "NA" : context.subTeam}
                />
              </StyledEngineProvider>
            </span>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
