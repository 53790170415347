import * as React from "react";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { CmContext } from "../../../../App";

export default function DatePickerRejoin() {
  const [value, setValue] = React.useState(null);
  const [context,setContext]=React.useContext(CmContext);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label="Date of Rejoining"
        value={value}
        onChange={(newValue) => {
          console.log(newValue.toLocaleDateString('en-CA'),"date of rejoining");

          setValue(newValue);
          context.updateOnboardStatusData.dojDate=newValue.toLocaleDateString('en-CA');
          setContext({...context});
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider> 
  );
}
