import axios from "axios";
import Swal from "sweetalert2";
import { MARK_ASM_INACTIVE_URL, MARK_ASM_ACTIVE_URL, ASM_REMAP_URL } from "../../utils";

const updateOnboardStatus = (
  fse_user_id,
  hr_admin_id,
  comment,
  action_type
) => {
  const url = MARK_ASM_INACTIVE_URL;
  const body = {
    asm_id: fse_user_id,
    hr_admin_id: hr_admin_id,
    comment: `${comment}`,
    action_type: `${action_type}`,
  };
  axios
    .put(url, body)
    .then((r) => {
      if (r.data.error) {
      } else {
        console.log(r, "marked inactive backend response");
        Swal.fire({
          icon: "success",
          title: "Marked Inactive",
        });
      }
    })
    .catch((e) => {
      Swal.fire({
        icon: "error",
        text: JSON.stringify({
          e,
        }),
      });
    });
};
const markAsmActiveApiCall = (
  fse_user_id,
  hr_admin_id,
  comment,
  action_type,
  date_of_rejoin,
  emp_id
) => {
  const url = MARK_ASM_ACTIVE_URL;
  const body = {
    asm_id: fse_user_id,
    hr_admin_id: hr_admin_id,
    comment: `${comment}`,
    action_type: `${action_type}`,
  };
  axios
    .put(url, body)
    .then((r) => {
      if (r.data.error) {
      } else {
        console.log(r, "marked active backend response");
        Swal.fire({
          icon: "success",
          title: "Marked Active",
        });
      }
    })
    .catch((e) => {
      Swal.fire({
        icon: "error",
        text: JSON.stringify({
          e,
        }),
      });
    });
};
const remapAsm = (asm_id, cm_id, hr_admin_id) => {
  return new Promise((resolve, reject) => {
    fetch(ASM_REMAP_URL, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        asm_id,
        cm_id,
        hr_admin_id,
      }),
    })
      .then((r) => r.json())
      .then((r) => {
        resolve(r);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export { updateOnboardStatus, markAsmActiveApiCall, remapAsm };
