// import * as React from "react";
// import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
// import Modal from "@mui/material/Modal";
// import DatePickerSoi from "./DatePickerSoi/DatePickerSoi";
// import { TextField } from "@mui/material";
// import { StyledEngineProvider } from "@mui/material/styles";
// import { SEND_FINAL_OFFER_LETTER_URL } from "../../../utils";
// import context from "react-bootstrap/esm/AccordionContext";
// import Swal from "sweetalert2";
// import { CmContext } from "../../../App";
// import "./SendOlModal.css";
// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 700,
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
// };

// export default function SendOlModal({ open, setOpen, formData, asm_id }) {
//   const [disableSubmit, setDisableSubmit] = React.useState(false);
//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);
//   const [context, setContext] = React.useContext(CmContext);

//   // console.log(formData?.doj);
//   const handleSubmit = (e) => {
//     setDisableSubmit(true);
//     console.log(context.soiDate, "context soiDate");
//     console.log(context.soiEmpId, "context soiEmpId");
//     console.log(context.activeAsmId, "context activeAsmId");
//     if (
//       context.soiDate &&
//       context.soiEmpId &&
//       context.activeAsmId + "" !== "-1" &&
//       context.soiEmpId > 0
//       // (formData?.doj == "Invalid date")
//     ) {
//       fetch(SEND_FINAL_OFFER_LETTER_URL, {
//         method: "POST",
//         headers: {
//           "content-type": "application/json",
//         },
//         body: JSON.stringify({
//           asm_id: context.activeAsmId,
//           employee_id: context.soiEmpId,
//           doj: context.soiDate,
//         }),
//       })
//         .then((r) => {
//           if (r.ok) {
//             return r.json();
//           } else {
//             throw new Error("Unable to fetch from server");
//           }
//         })
//         .then((r) => {
//           if (!r.error && r.message === "Sent") {
//             Swal.fire({
//               icon: "success",
//               title: "Offer Letter Sent",
//             });
//             setOpen(false);
//             setDisableSubmit(false);
//           }
//         })
//         .catch((error) => {
//           Swal.fire({
//             icon: "error",
//             text: `Error : ${error}`,
//           });
//           setOpen(false);
//           setDisableSubmit(false);
//         });
//     } else {
//       Swal.fire({
//         icon: "error",
//         text: "please select date of joining and enter employee id",
//       });
//       setOpen(false);
//       setDisableSubmit(false);
//     }
//   };
//   return (
//     <div>
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Box sx={style}>
//           <Typography id="modal-modal-title" variant="h6" component="h2">
//             Send Final Offer Letter
//           </Typography>
//           <Typography id="modal-modal-description" sx={{ mt: 2 }}>
//             <div className="soi-form">
//               {/* {formData?.doj== "Invalid date"? (
//                 <StyledEngineProvider injectFirst>
//                   <DatePickerSoi />

//                   <TextField
//                     min={0}
//                     id="outlined-number"
//                     label="Employee Id"
//                     type="number"
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                     onChange={(e) => {
//                       console.log(e.target.value, "empId selected");
//                       context.soiEmpId = e.target.value;
//                       setContext({ ...context });
//                     }}
//                   />
//                 </StyledEngineProvider>
//               ) : (
//                 <></>
//               )} */}
//               <StyledEngineProvider injectFirst>
//                 <DatePickerSoi />

//                 <TextField
//                   min={0}
//                   id="outlined-number"
//                   label="Employee Id"
//                   type="number"
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                   onChange={(e) => {
//                     console.log(e.target.value, "empId selected");
//                     context.soiEmpId = e.target.value;
//                     setContext({ ...context });
//                   }}
//                 />
//               </StyledEngineProvider>
//               <button
//                 disabled={disableSubmit}
//                 style={{ opacity: `${disableSubmit ? "0.2" : "1"}` }}
//                 onClick={handleSubmit}
//               >
//                 Submit
//               </button>
//             </div>
//           </Typography>
//         </Box>
//       </Modal>
//     </div>
//   );
// }


import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import DatePickerSoi from "./DatePickerSoi/DatePickerSoi";
import { TextField } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import { SEND_FINAL_OFFER_LETTER_URL } from "../../../utils";
import context from "react-bootstrap/esm/AccordionContext";
import Swal from "sweetalert2";
import { CmContext } from "../../../App";
import "./SendOlModal.css";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function SendOlModal({ open, setOpen }) {
  const [disableSubmit, setDisableSubmit] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [context, setContext] = React.useContext(CmContext);
  const handleSubmit = (e) => {
    setDisableSubmit(true);
    console.log(context.soiDate, "context soiDate");
    console.log(context.soiEmpId, "context soiEmpId");
    console.log(context.activeAsmId, "context activeAsmId");
    if (
      context.soiDate &&
      context.soiEmpId &&
      context.activeAsmId + "" !== "-1" &&
      context.soiEmpId>0
      
    ) {
      fetch(SEND_FINAL_OFFER_LETTER_URL, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          asm_id: context.activeAsmId,
          employee_id: context.soiEmpId,
          doj: context.soiDate,
        }),
      })
        .then((r) => {
          if (r.ok) {
            return r.json();
          } else {
            throw new Error("Unable to fetch from server");
          }
        })
        .then((r) => {
          if (!r.error && r.message === "Sent") {
            Swal.fire({
              icon: "success",
              title: "Offer Letter Sent",
            });
            setOpen(false);
            setDisableSubmit(false);
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            text: `Error : ${error}`,
          });
          setOpen(false);
          setDisableSubmit(false);
        });
    } else {
      Swal.fire({
        icon: "error",
        text: "please select date of joining and enter employee id",
      });
      setOpen(false);
      setDisableSubmit(false);
    }
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Send Final Offer Letter
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="soi-form">
              <StyledEngineProvider injectFirst>
                <DatePickerSoi />

                <TextField
                  min={0}
                  id="outlined-number"
                  label="Employee Id"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    console.log(e.target.value, "empId selected");
                    context.soiEmpId = e.target.value;
                    setContext({ ...context });
                  }}
                />
              </StyledEngineProvider>
              <button
                disabled={disableSubmit}
                style={{ opacity: `${disableSubmit ? "0.2" : "1"}` }}
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
