import Loader from "../Loader/Loader";
// import "./ProjectListUnique.css";
import { connect } from "react-redux";
import { useContext, useEffect } from "react";
import { CmContext } from "../../App";
import { projectListApi } from "./../SideBar/ApiCalls";
import { startGetMyStats } from "../action/myStats";
import { startGetMyTeam } from "../action/myTeam";
import { startGetAsmCheckin } from "../action/asmCheckin";
import { startGetLeadRegister } from "../action/leadRegister";
import { startGetProjectDetaill } from "../action/projectDetail";
import { startGetTotalAttendance } from "../action/totalAttendance";
import { startGetAsmOverView } from "../action/asmChart";
import { Link, useHistory } from "react-router-dom";
import { getAsmCount, getLeadCount } from "./ApiCalls";
import { motion } from "framer-motion";
import "./ProjectListUnique.css";

function ProjectListUnique(props) {
  const [context, setContext] = useContext(CmContext);
  const history = useHistory();
  useEffect(() => {
    projectListApi(localStorage.getItem("hr_admin_id"), context, setContext);
    context.sidebarS = context.sidebarS.fill(true);
    context.sidebarS[1] = false;
    setContext({ ...context });
    //get lead count
    getAsmCount()
      .then((r) => {
        context.projectWiseAsmCount = [...r.data];
        setContext({ ...context });
      })
      .catch((err) => {});
    getLeadCount()
      .then((r) => {
        context.projectWiseLeadCount = [...r.data];
        setContext({ ...context });
      })
      .catch((err) => {});
  }, []);

  const handleView = (project_title) => {
    // props.dispatch(startGetMyStats(e));
    // console.log(id,"button view")
    // console.log(e,"button view")
    // localStorage.setItem("sow_id", e);
    // props.dispatch(startGetMyTeam(e));
    // props.dispatch(startGetAsmCheckin(e));
    // props.dispatch(startGetLeadRegister(e));
    // props.dispatch(startGetProjectDetaill(e));
    // props.dispatch(startGetTotalAttendance(e));
    // props.dispatch(startGetAsmOverView(e));
    // context.sidebar2 = true;
    context.activeProjectTitle = project_title;
    setContext({ ...context });
    history.push("/dash/sowList");
  };
  return (
    <>
      {Object.keys(context.projectList).length >= 1 ? (
        <div className="dashboard_total">
          <div className="dash_row2">
            <h3 className="dash_list_head">project list:</h3>
          </div>
          <div className="dash_row3">
            {context.projectListUnique.map((proj) => (
              <div className="dash_card" key={proj.sow_id}>
                <div>
                  <div className="d_row1">
                    <img
                      src={proj.brand_logo}
                      alt="profile"
                      className="company_pro"
                    />
                    <div className="dash_p1">Company name:</div>
                    <div className="dash_h1">{proj.company_name}</div>
                  </div>
                  <div className="d_row2">
                    <div className="dash_col1">
                      <div className="sub_row">
                        <p className="pro_name">Project name:</p>
                        <p className="pro_name1">{proj.project_title}</p>
                      </div>
                      {/* <div className="sub_row">
                      <p className="pro_name">Start date:</p>
                      <p className="pro_name1">{proj.start_date}</p>
                    </div> */}
                      {/* <div className="sub_row">
                      <p className="pro_name">End date:</p>
                      <p className="pro_name1">{proj.end_date}</p>
                    </div> */}
                    </div>
                    {/* <div className="dash_col2">
                    <div className="sub_row">
                      <p className="pro_name">SOW id:</p>
                      <p className="pro_name1">{proj.sow_id}</p>
                    </div>
                    <div className="sub_row">
                      <p className="pro_name">Location:</p>
                      <p className="pro_name1">{proj.city}</p>
                    </div>
                    <div className="sub_row">
                      <p className="pro_name">Job Category</p>
                      <p className="pro_name1">Merchant-onboarding</p>
                    </div>
                  </div> */}
                  </div>
                </div>

                {/* <div className="d_row3">
              <h2 className="d_header">JIOMART:</h2>
              <p className="d_p1">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Accusantium cumque, quis saepe aliquam dolore provident veniam
                deserunt minus possimus ducimus nam expedita sed sequi eveniet
                error quam aperiam quaerat voluptas.
              </p>
            </div> */}
                {context.projectWiseLeadCount.length > 0 && (
                  <div className="d_row3">
                    <motion.div
                      className="dash_small_card"
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                    >
                      <p className="dash_small_p1">Leads</p>
                      <p className="dash_small_p2">
                        {/* {JSON.stringify({r:context.projectWiseLeadCount.filter((obj) => {
                          return obj.project_title.toLowerCase() == proj.project_title.toLowerCase();
                        })[0].total})} */}
                        {context.projectWiseLeadCount.filter((obj) => {
                          return (
                            obj.project_title.toLowerCase() ==
                            proj.project_title.toLowerCase()
                          );
                        }).length > 0
                          ? context.projectWiseLeadCount.filter((obj) => {
                              return (
                                obj.project_title.toLowerCase() ==
                                proj.project_title.toLowerCase()
                              );
                            })[0].total
                          : null}
                      </p>
                      <p className="dash_small_p3">
                        +
                        {context.projectWiseLeadCount.filter((obj) => {
                          return (
                            obj.project_title.toLowerCase() ==
                            proj.project_title.toLowerCase()
                          );
                        }).length > 0
                          ? context.projectWiseLeadCount.filter((obj) => {
                              return (
                                obj.project_title.toLowerCase() ==
                                proj.project_title.toLowerCase()
                              );
                            })[0].total_today
                          : null}
                      </p>
                    </motion.div>
                    <motion.div
                      className="dash_small_card"
                      style={{
                        background: "#FFF9F0",
                        border: "1px solid #cb552833",
                      }}
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      transition={{ delay: 0.1 }}
                    >
                      <p className="dash_small_p1">Pending</p>
                      <p className="dash_small_p2" style={{ color: "#FFB100" }}>
                        {context.projectWiseLeadCount.filter((obj) => {
                          return (
                            obj.project_title.toLowerCase() ==
                            proj.project_title.toLowerCase()
                          );
                        }).length > 0
                          ? context.projectWiseLeadCount.filter((obj) => {
                              return (
                                obj.project_title.toLowerCase() ==
                                proj.project_title.toLowerCase()
                              );
                            })[0].pending
                          : null}
                      </p>
                      <p
                        className="dash_small_p3"
                        style={{ background: "#FEB12A34" }}
                      >
                        +
                        {context.projectWiseLeadCount.filter((obj) => {
                          return (
                            obj.project_title.toLowerCase() ==
                            proj.project_title.toLowerCase()
                          );
                        }).length > 0
                          ? context.projectWiseLeadCount.filter((obj) => {
                              return (
                                obj.project_title.toLowerCase() ==
                                proj.project_title.toLowerCase()
                              );
                            })[0].pending_today
                          : null}
                      </p>
                    </motion.div>
                    <motion.div
                      className="dash_small_card"
                      style={{ background: "#EAFAF5" }}
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      transition={{ delay: 0.2 }}
                    >
                      <p className="dash_small_p1">Approved</p>
                      <p className="dash_small_p2" style={{ color: "#118026" }}>
                        {context.projectWiseLeadCount.filter((obj) => {
                          return (
                            obj.project_title.toLowerCase() ==
                            proj.project_title.toLowerCase()
                          );
                        }).length > 0
                          ? context.projectWiseLeadCount.filter((obj) => {
                              return (
                                obj.project_title.toLowerCase() ==
                                proj.project_title.toLowerCase()
                              );
                            })[0].approved
                          : null}
                      </p>
                      <p
                        className="dash_small_p3"
                        style={{ background: "#CAEFE5" }}
                      >
                        +
                        {context.projectWiseLeadCount.filter((obj) => {
                          return (
                            obj.project_title.toLowerCase() ==
                            proj.project_title.toLowerCase()
                          );
                        }).length > 0
                          ? context.projectWiseLeadCount.filter((obj) => {
                              return (
                                obj.project_title.toLowerCase() ==
                                proj.project_title.toLowerCase()
                              );
                            })[0].approved_today
                          : null}
                      </p>
                    </motion.div>
                    <motion.div
                      className="dash_small_card"
                      style={{
                        background: "#FFF9F0",
                        border: "1px solid #cb552833",
                      }}
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      transition={{ delay: 0.3 }}
                    >
                      <p className="dash_small_p1">Rejected</p>
                      <p className="dash_small_p2" style={{ color: "#FFB100" }}>
                        {context.projectWiseLeadCount.filter((obj) => {
                          return (
                            obj.project_title.toLowerCase() ==
                            proj.project_title.toLowerCase()
                          );
                        }).length > 0
                          ? context.projectWiseLeadCount.filter((obj) => {
                              return (
                                obj.project_title.toLowerCase() ==
                                proj.project_title.toLowerCase()
                              );
                            })[0].rejected
                          : null}
                      </p>
                      <p
                        className="dash_small_p3"
                        style={{ background: "#FEB12A34" }}
                      >
                        +
                        {context.projectWiseLeadCount.filter((obj) => {
                          return (
                            obj.project_title.toLowerCase() ==
                            proj.project_title.toLowerCase()
                          );
                        }).length > 0
                          ? context.projectWiseLeadCount.filter((obj) => {
                              return (
                                obj.project_title.toLowerCase() ==
                                proj.project_title.toLowerCase()
                              );
                            })[0].rejected_today
                          : null}
                      </p>
                    </motion.div>
                    <motion.div
                      className="dash_small_card"
                      style={{ background: "#EAFAF5" }}
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      transition={{ delay: 0.5 }}
                    >
                      <p className="dash_small_p1">TSMs</p>
                      <p className="dash_small_p2" style={{ color: "#118026" }}>
                        {context.projectWiseAsmCount.filter((obj) => {
                          return (
                            obj.project_title.toLowerCase() ==
                            proj.project_title.toLowerCase()
                          );
                        }).length > 0
                          ? context.projectWiseAsmCount.filter((obj) => {
                              return (
                                obj.project_title.toLowerCase() ==
                                proj.project_title.toLowerCase()
                              );
                            })[0].tsms
                          : null}
                      </p>
                    </motion.div>
                  </div>
                )}
                <div className="d_row4">
                  {/* <Link to={`/dash/myStats`}> */}
                  <div
                    className="dash_button"
                    value={proj.sow_id}
                    onClick={(value) => handleView(proj.project_title)}
                  >
                    <p className="dash_b_p1">view</p>
                    <img
                      src={window.location.origin + "/images/arrow.svg"}
                      alt="profile"
                      className="view_but"
                    />
                  </div>
                  {/* </Link> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : Object.keys(context.projectList).length !== 0 ? (
        <div className="loader">
          <Loader />
        </div>
      ) : (
        <div className="loader_nodata">
          <p>No Data is Found</p>
        </div>
      )}
    </>
  );
}

export default connect()(ProjectListUnique);
