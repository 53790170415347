import "./style.css";
import React, { Component } from "react";
import GoogleLogin from "react-google-login";
import { checkEmail } from "../../utils";

export default class MyGoogleLogin extends Component {
  responseGoogle = (r1) => {
    // this.props.loginFunc(r);
    console.log(r1, "Google Response");
    if (r1.accessToken) {
      const email = r1.profileObj.email;
      const fetchCall = async () => {
        const response1 = await fetch(checkEmail, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            email,
          }),
        });
        console.log(response1,"inside response")
        if(response1.ok){
          const response=await response1.json();
          console.log(response,"get response email_status");
          if(response.email_status){
            localStorage.setItem('hr_admin_id',response.hr_admin_id)
            localStorage.setItem('is_active',response.is_active)
            localStorage.setItem('profileObj',JSON.stringify({...r1.profileObj}))
            this.props.loginFunc(r1);
            console.log(response,"error check login email false","response from taskmo server");
            this.props.setLoginError("");
          }else{
            console.log("error check login email true");
            this.props.logoutFunc();
            this.props.setLoginError(
              "You don't have access, please contact admin"
            );
          }
          
        }else{
          console.log("error check login email true");
            this.props.logoutFunc();
            this.props.setLoginError(
              "You don't have access, please contact admin"
            );
        }
      };
      fetchCall();
      // fetch(checkEmail, {
      //   method: "POST",
      //   headers: {
      //     "Content-type": "application/json",
      //   },
      //   body: JSON.stringify({
      //     email_id,
      //   }),
      // })
        // .then((r) => r.json())
        // .then((r) => {
        //   console.log(r);
        //   if (r.error) {
        //     console.log("error check login email true");
        //     this.props.logoutFunc();
        //     this.props.setLoginError(
        //       "You don't have access, please contact admin"
        //     );
        //   } else {
        //     this.props.loginFunc(r1);
        //     console.log("error check login email false");
        //     this.props.setLoginError("");
        //   }
        // })
        // .catch((e) => {
        //   console.error("Error While login: ", e);
        //   this.props.logoutFunc();
        // });
    } else {
      this.props.logoutFunc();
    }
  };
  render() {
    return (
      <>
        <GoogleLogin
          clientId="800470593510-snvcbiteqg9okli40a87lpj3dib6to5e.apps.googleusercontent.com" //client id
          // clientId="800470593510-v4rijm08vf4iapke1q4jr1k1odg62dp8.apps.googleusercontent.com" //local host
          buttonText="Sign In With Google"
          onSuccess={this.responseGoogle}
          onFailure={this.responseGoogle}
          cookiePolicy={`single_host_origin`}
        />
      </>
    );
  }
}
