import Axios from "axios";

const axios = Axios.create({
  baseURL: "https://app.backend.taskmo.co/pa/",
});

export const axios2 = Axios.create({
  baseURL: "https://nodeapi.taskmo.com/v1/hr",
});

export default axios;
