import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { remapFse, updateOnboardStatus } from "./ApiCalls";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";

import { CmContext } from "./../../App";
import Swal from "sweetalert2";
import { ASM_IMAGE_BASE_URL } from "../../utils";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function AsmListToRemapFse({ open, setOpen }) {
  //   const handleOpen = () => setOpen(true);
  const [context, setContext] = React.useContext(CmContext);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Select TSM
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <List
              sx={{
                width: "100%",
                maxWidth: 360,
                maxHeight: 400,
                overflow: "overlay",
                bgcolor: "background.paper",
              }}
            >
              {context.asmList.map(
                ({
                  asm_id,
                  full_name:tl_name,
                  mobile_number,
                  profile_image,
                  is_active,
                }) => {
                  return (
                    <>
                      {/* {is_active === "onboarded" || is_active === "none" */}
                      { true
                      ? (
                        <>
                          <ListItem
                            alignItems="flex-start"
                            button
                            onClick={() => {
                              if (
                                window.confirm(
                                  "Are you sure to remap this fse to " + tl_name
                                )
                              ) {
                                setOpen(false);
                                remapFse(context.activeFseId, asm_id,localStorage.getItem('hr_admin_id'))
                                  .then((r) => {
                                    Swal.fire({
                                      icon: "success",
                                      title: "Remaped to " + tl_name,
                                    });
                                    console.log(
                                      r,
                                      "<- response from remaping the fse"
                                    );
                                  })
                                  .catch((err) => {
                                    Swal.fire({
                                      icon: "error",
                                      title: JSON.stringify({
                                        error: err,
                                      }),
                                    });
                                  });
                              }
                            }}
                          >
                            {/* <ListItemAvatar>
                              <Avatar
                                alt={`${tl_name}`}
                                src={`${ASM_IMAGE_BASE_URL}${profile_image}`}
                              />
                            </ListItemAvatar> */}
                            <ListItemText
                              primary={`${tl_name}`}
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    sx={{ display: "inline" }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    {mobile_number}
                                  </Typography>
                                  {/* {
                              " — Do you have Paris recommendations? Have you ever…"
                            } */}
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <Divider variant="inset" component="li" />
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                }
              )}
            </List>
          </Typography>
          {/* <button
            onClick={() => {
              if(context.updateOnboardStatusData.comment.length<1){
                handleClose();
                Swal.fire({
                  icon:"warning",
                  title:"Empty Comment"
                })
              }else{
                // handleClose();
                // console.log("inactive comment popup" , context.updateOnboardStatusData)
                // updateOnboardStatus(
                //   context.updateOnboardStatusData.fse_user_id,
                //   context.updateOnboardStatusData.hr_admin_id,
                //   context.updateOnboardStatusData.comment,
                //   context.updateOnboardStatusData.action_type
                // );
              }
            }}
          >
            Save
          </button> */}
        </Box>
      </Modal>
    </div>
  );
}
