import { useState } from "react";
import stateCity from "../dataset/state_city";
import "./AddCm.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect } from "react";
import { BASE_URL, RM_URL } from "../../utils";
import axios from "axios";
import Swal from "sweetalert2";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

function ValidateEmail(email) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true;
  }
  // alert("You have entered an invalid email address!")
  return false;
}

function AddCm() {
  const [formData, setFormData] = useState({
    profileImage: "",
    full_name: "",
    mobile_number: "",
    email_id: "",
    state: "",
    city: "",
    pincode: "",
    dateOfOnboard: "",
    doj: "",
    aadhar_number: "",
    pan_number: "",
    rm_id: "",
    aadhar_front_image: "",
    aadhar_back_image: "",
    dlFrontImage: "",
    dlBackImage: "",
    bankImage: "",
    panImage: "",
    aadharFrontImage: "",
    aadharBackImage: "",
    dl_front_image: "",
    dl_back_image: "",
    bank_statement_image: "",
    pan_image: "",
    profile_image: "",
  });
  const states = stateCity.map((obj) => obj.state);
  const uniqueStates = [...new Set(states)];
  const sortedStates = uniqueStates.sort();
  const [rmList, setRmList] = useState([]);
  useEffect(() => {
    const asyncFunc = async () => {
      const res1 = await fetch(RM_URL);
      const res = await res1.json();
      console.log(res);
      if (res.error) {
      } else {
        setRmList([...res.data]);
      }
    };
    asyncFunc();
  }, []);

  const uploadImage = async (e) => {
    try {
      console.log(e.target.value, "inside adhar front");
      // const axiosData = {
      //   "type" : e.target.name,
      //   "image" : e.target.value
      // }
      const axiosData = new FormData();

      axiosData.append("image", e.target.files[0]);
      axiosData.append("type", `${e.target.name}`);
      console.log(e.target.files[0], "axiosData,inside");

      const uploadData = await axios
        .post("https://app.backend.taskmo.co/hr/upload_image", axiosData)
        .then((res) => {
          console.log(res.data.path, "successful");
          formData[e.target.name] = `${res.data.path}`;
          if (res.data.type === "profile_image") {
            formData[
              "profileImage"
            ] = `https://app.backend.taskmo.co/cm_images/${res.data.path}`;
          }
          if (res.data.type === "aadhar_front_image") {
            formData[
              "aadharFrontImage"
            ] = `https://app.backend.taskmo.co/cm_images/${res.data.path}`;
          }
          if (res.data.type === "aadhar_back_image") {
            formData[
              "aadharBackImage"
            ] = `https://app.backend.taskmo.co/cm_images/${res.data.path}`;
          }
          if (res.data.type === "dl_front_image") {
            formData[
              "dlFrontImage"
            ] = `https://app.backend.taskmo.co/cm_images/${res.data.path}`;
          }
          if (res.data.type === "dl_back_image") {
            formData[
              "dlBackImage"
            ] = `https://app.backend.taskmo.co/cm_images/${res.data.path}`;
          }
          if (res.data.type === "bank_statement_image") {
            formData[
              "bankImage"
            ] = `https://app.backend.taskmo.co/cm_images/${res.data.path}`;
          }
          if (res.data.type === "pan_image") {
            formData[
              "panImage"
            ] = `https://app.backend.taskmo.co/cm_images/${res.data.path}`;
          }
          setFormData({ ...formData });
          Swal.fire({
            icon: "success",
            title: "Uploaded",
            text: ` Image ${res.data.path}`,
            timer: 700,
            showConfirmButton: false,
            showCancelButton: false,
          });
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Upload Error",
            text: ` Image uploading failed try again`,
            timer: 700,
            showConfirmButton: false,
            showCancelButton: false,
          });
        });
    } catch (err) {
      console.log(err);
      Swal.fire({
        icon: "error",
        title: "Upload Error",
        text: ` Image uploading failed try again`,
        timer: 700,
        showConfirmButton: false,
        showCancelButton: false,
      });
    }
  };
  const ImageUpload = (e) => {
    const fileSelect = document.getElementById("fileSelect"),
      fileElem = document.getElementById("fileElem");
    fileSelect.addEventListener(
      "click",
      function (e) {
        if (fileElem) {
          fileElem.click();
          // setFormData()
        }
      },
      false
    );
  };
  const ImageUpload1 = (e) => {
    const fileSelect = document.getElementById("fileSelect1"),
      fileElem = document.getElementById("fileElem1");
    fileSelect.addEventListener(
      "click",
      function (e) {
        if (fileElem) {
          fileElem.click();
          // setFormData()
        }
      },
      false
    );
  };
  const ImageUpload2 = (e) => {
    const fileSelect = document.getElementById("fileSelect2"),
      fileElem = document.getElementById("fileElem2");
    fileSelect.addEventListener(
      "click",
      function (e) {
        if (fileElem) {
          fileElem.click();
          // setFormData()
        }
      },
      false
    );
  };
  const ImageUpload3 = (e) => {
    const fileSelect = document.getElementById("fileSelect3"),
      fileElem = document.getElementById("fileElem3");
    fileSelect.addEventListener(
      "click",
      function (e) {
        if (fileElem) {
          fileElem.click();
          // setFormData()
        }
      },
      false
    );
  };
  const ImageUpload4 = (e) => {
    const fileSelect = document.getElementById("fileSelect4"),
      fileElem = document.getElementById("fileElem4");
    fileSelect.addEventListener(
      "click",
      function (e) {
        if (fileElem) {
          fileElem.click();
          // setFormData()
        }
      },
      false
    );
  };
  const ImageUpload5 = (e) => {
    const fileSelect = document.getElementById("fileSelect5"),
      fileElem = document.getElementById("fileElem5");
    fileSelect.addEventListener(
      "click",
      function (e) {
        if (fileElem) {
          fileElem.click();
          // setFormData()
        }
      },
      false
    );
  };
  const ImageUpload6 = (e) => {
    const fileSelect = document.getElementById("fileSelect6"),
      fileElem = document.getElementById("fileElem6");
    fileSelect.addEventListener(
      "click",
      function (e) {
        if (fileElem) {
          fileElem.click();
          // setFormData()
        }
      },
      false
    );
  };

  const handleSubmit = async () => {
    try {
      const formSubmit = await axios
        .post("https://app.backend.taskmo.co/hr/add_cm", formData)
        .then((res) => {
          if (res.data.error) {
            Swal.fire({
              icon: "error",
              title: `${res.data.message}`,
              // timer:700,
              showConfirmButton: true,
              showCancelButton: false,
            });
          } else {
            console.log(res.data, "successed in uploading data");
            Swal.fire({
              icon: "success",
              title: "Submitted",
              timer: 700,
              showConfirmButton: false,
              showCancelButton: false,
            }).then(() => {
              window.location = `${BASE_URL}/dash`;
            });
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: `error ${err}`,
            // timer:700,
            showConfirmButton: true,
            // showCancelButton:false
          });
        });
    } catch (err) {
      console.log(err);
    }
  };

  console.log(formData, "form data total");

  //https://app.backend.taskmo.co/cm_images/image-1629875185305.svg
  return (
    <div className="addcm-container">
      <div className="container-fluid">
        <div className="row">
          <div className="col mt-2">
            <p className="heading-city-manager">
              Add City Manager Details &#x3A;
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-2 mt-5">
            <input
              type="file"
              id="fileElem6"
              accept="image/*"
              style={{ display: "none" }}
              name="profile_image"
              // value={formData.profile_image}
              onChange={(value) => {
                uploadImage(value);
              }}
            />
            <div
              className="documents-box"
              id="fileSelect6"
              onClick={ImageUpload6}
            >
              <div className="pic-container">
                <img
                  src={
                    !formData.profile_image
                      ? window.location.origin +
                        "/images/profile_default_new.svg"
                      : formData.profileImage
                  }
                  alt="aadharfront"
                  className="image_upload_profile"
                />
              </div>
            </div>
          </div>
          <div className="col-10 border-form">
            <div className="form-container">
              <form className="form-details">
                <div className="col-6 input-field">
                  <label className="cm_label" htmlFor="Name">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control property-box"
                    placeholder="Name"
                    onChange={(e) => {
                      formData.full_name = e.target.value.trim();
                      setFormData({ ...formData });
                    }}
                  />
                </div>
                <div className="col-6 input-field">
                  <label className="cm_label" htmlFor="Contact number">
                    Contact number
                  </label>
                  <input
                    type="text"
                    className="form-control property-box"
                    placeholder="Contact number"
                    onChange={(e) => {
                      if (e.target.value.trim().length === 10) {
                        formData.mobile_number = e.target.value.trim();
                        setFormData({ ...formData });
                      } else if (e.target.value.trim().length > 10) {
                        e.target.value = e.target.value.substr(0, 10);
                      }
                    }}
                  />
                </div>

                {/* ssecond input row */}

                <div className="col-6 input-field">
                  <label className="cm_label" htmlFor="Email ID">
                    Email ID
                  </label>
                  <input
                    type="text"
                    className="form-control property-box"
                    placeholder="Email ID"
                    onChange={(e) => {
                      if (ValidateEmail(e.target.value.trim())) {
                        formData.email_id = e.target.value.trim();
                        setFormData({ ...formData });
                      }
                    }}
                  />
                </div>
                <div className="col-6 input-field">
                  <label className="cm_label" htmlFor="Choose your State">
                    Choose your State
                  </label>
                  <select
                    className="form-control property-box"
                    // placeholder="State"
                    onChange={(e) => {
                      formData.state = e.target.value;
                      setFormData({ ...formData });
                    }}
                  >
                    <option
                      className="selectPlaceholder"
                      value=""
                      disabled
                      selected
                      hidden
                    >
                      Choose your State
                    </option>
                    {sortedStates.map((el, idx) => {
                      return (
                        <option key={idx} value={el}>
                          {el}
                        </option>
                      );
                    })}
                  </select>
                </div>

                {/* third input row */}

                <div className="col-6 input-field">
                  <label className="cm_label" htmlFor=" Choose your City">
                    Choose your City
                  </label>
                  <select
                    className="form-control property-box"
                    placeholder="City"
                    onChange={(e) => {
                      formData.city = e.target.value;
                      setFormData({ ...formData });
                    }}
                  >
                    <option
                      className="selectPlaceholder"
                      value=""
                      disabled
                      selected
                      hidden
                    >
                      Choose your City
                    </option>
                    {formData.state.trim().length !== 0
                      ? stateCity
                          .filter(({ state: s }) => {
                            return formData.state === s;
                          })
                          .map(({ name: city }, idx) => {
                            return (
                              <option key={idx} value={city}>
                                {city}
                              </option>
                            );
                          })
                      : null}
                  </select>
                </div>
                <div className="col-6 input-field">
                  <label className="cm_label" htmlFor="Pincode">
                    Pincode
                  </label>
                  <input
                    type="text"
                    className="form-control property-box"
                    placeholder="Pincode"
                    onChange={(e) => {
                      formData.pincode = e.target.value;
                      setFormData({ ...formData });
                    }}
                  />
                </div>

                {/* fourth input row */}

                {/* <div className="col-6 input-field">
                  <input
                    type="text"
                    className="form-control property-box"
                    placeholder="Date of onboarding (dd/mm/yy)"
                    onChange={(e) => {
                      formData.dateOfOnboard = e.target.value.trim();
                      setFormData({ ...formData });
                    }}
                  /> */}
                {/* <ReactDatePicker/> */}
                {/* <DatePicker selected={formData.dateOfOnboard} onChange={(date) => {
                      formData.dateOfOnboard=date;
                      setFormData({...formData})
                  }} /> */}
                {/* </div> */}
                <div className="col-6 input-field">
                  <label className="cm_label" htmlFor="Date of Joining">
                    Date of Joining
                  </label>
                  <input
                    type="text"
                    className="form-control property-box"
                    placeholder="dd/mm/yyyy"
                    onChange={(e) => {
                      const myDate = e.target.value.trim();
                      const arrangedDate = `${myDate.substring(
                        6,
                        10
                      )}-${myDate.substring(3, 5)}-${myDate.substring(0, 2)}`;
                      formData.doj = arrangedDate;
                      setFormData({ ...formData });
                    }}
                  />
                </div>

                {/* fifth inpu row */}

                <div className="col-6 input-field">
                  <label className="cm_label" htmlFor="Aadhar Number">
                    Aadhar Number
                  </label>
                  <input
                    type="text"
                    className="form-control property-box"
                    placeholder="Aadhar Number"
                    msax
                    zz
                    onChange={(e) => {
                      formData.aadhar_number = e.target.value.trim();
                      setFormData({ ...formData });
                    }}
                  />
                </div>
                <div className="col-6 input-field">
                  <label className="cm_label" htmlFor="PAN Number">
                    PAN Number
                  </label>
                  <input
                    type="text"
                    className="form-control property-box"
                    placeholder="PAN Number"
                    onChange={(e) => {
                      formData.pan_number = e.target.value.trim();
                      setFormData({ ...formData });
                    }}
                  />
                </div>

                {/* Sixth input row */}

                <div className="col-6 input-field">
                  <label className="cm_label" htmlFor="Regional manager">
                    Regional manager
                  </label>
                  <input
                    list="datalistOptions"
                    id="exampleDataList"
                    type="text"
                    className="form-control property-box"
                    placeholder="Regional manager"
                    onChange={(e) => {
                      if (e.target.value) {
                        const [id, rm_name] = e.target.value.split(",");
                        formData.rm_id = id;
                        setFormData({ ...formData });
                      }
                      setTimeout(() => {
                        console.log(formData);
                      }, 500);
                    }}
                  />
                  <datalist id="datalistOptions">
                    {rmList.map((obj, idx) => {
                      return (
                        <option value={obj.rm_id + "," + obj.rm_name}></option>
                      );
                    })}
                    {/* <option value="San Francisco"></option>
                    <option value="New York"></option>
                    <option value="Seattle"></option>
                    <option value="Los Angeles"></option>
                    <option value="Chicago"></option> */}
                  </datalist>
                </div>
              </form>
            </div>
            {/* Uploading Images */}
            <div className="row mt-3 border-form mt-5">
              <div className="border-box1">
                <p className="need-opacity mt-3">Aadhar Images</p>
                <div className="documents-box">
                  <div>
                    <input
                      type="file"
                      id="fileElem"
                      accept="image/*"
                      style={{ display: "none" }}
                      name="aadhar_front_image"
                      // value={formData.aadharFrontImage}
                      onChange={(value) => {
                        uploadImage(value);
                      }}
                    />

                    <div
                      className="addcm_flex_col"
                      id="fileSelect"
                      onClick={ImageUpload}
                    >
                      <p>Aadhar front image</p>
                      <div className={`myRowCenter`}>
                        <img
                          src={
                            !formData.aadhar_front_image
                              ? window.location.origin +
                                "/images/Upload_image.svg"
                              : formData.aadharFrontImage
                          }
                          alt="aadharfront"
                          className="image_upload_api"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <input
                      type="file"
                      id="fileElem1"
                      accept="image/*"
                      style={{ display: "none" }}
                      name="aadhar_back_image"
                      // value={formData.aadharBackImage}
                      onChange={(value) => {
                        uploadImage(value);
                      }}
                    />

                    <div
                      className="addcm_flex_col"
                      id="fileSelect1"
                      onClick={ImageUpload1}
                    >
                      <p>Aadhar back image</p>
                      <div className={`myRowCenter `}>
                        <img
                          src={
                            !formData.aadhar_back_image
                              ? window.location.origin +
                                "/images/Upload_image.svg"
                              : formData.aadharBackImage
                          }
                          alt="aadharfront"
                          className="image_upload_api"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3 border-form mt-5">
              <div className="border-box1">
                <p className="need-opacity mt-3">DL Images</p>
                <div className="documents-box">
                  <div>
                    <input
                      type="file"
                      id="fileElem2"
                      accept="image/*"
                      style={{ display: "none" }}
                      name="dl_front_image"
                      // value={formData.dlFrontImage}
                      onChange={(value) => {
                        uploadImage(value);
                      }}
                    />

                    <div
                      className="addcm_flex_col"
                      id="fileSelect2"
                      onClick={ImageUpload2}
                    >
                      <p>DL front image</p>
                      <div className={`myRowCenter `}>
                        <img
                          src={
                            !formData.dl_front_image
                              ? window.location.origin +
                                "/images/Upload_image.svg"
                              : formData.dlFrontImage
                          }
                          alt="aadharfront"
                          className="image_upload_api"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <input
                      type="file"
                      id="fileElem3"
                      accept="image/*"
                      style={{ display: "none" }}
                      name="dl_back_image"
                      // value={formData.dlBackImage}
                      onChange={(value) => {
                        uploadImage(value);
                      }}
                    />

                    <div
                      className="addcm_flex_col"
                      id="fileSelect3"
                      onClick={ImageUpload3}
                    >
                      <p>DL back image</p>
                      <div className={`myRowCenter `}>
                        <img
                          src={
                            !formData.dl_back_image
                              ? window.location.origin +
                                "/images/Upload_image.svg"
                              : formData.dlBackImage
                          }
                          alt="aadharfront"
                          className="image_upload_api"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3 border-form mt-5">
              <div className="border-box1">
                <p className="need-opacity mt-3">Bank Statement And PAN</p>
                <div className="documents-box">
                  <div>
                    <input
                      type="file"
                      id="fileElem4"
                      accept="image/*"
                      style={{ display: "none" }}
                      name="bank_statement_image"
                      // value={formData.bankImage}
                      onChange={(value) => {
                        uploadImage(value);
                      }}
                    />

                    <div
                      className="addcm_flex_col"
                      id="fileSelect4"
                      onClick={ImageUpload4}
                    >
                      <p>Bank Statement</p>
                      <div className={`myRowCenter `}>
                        <img
                          src={
                            !formData.bank_statement_image
                              ? window.location.origin +
                                "/images/Upload_image.svg"
                              : formData.bankImage
                          }
                          alt="aadharfront"
                          className="image_upload_api"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <input
                      type="file"
                      id="fileElem5"
                      accept="image/*"
                      style={{ display: "none" }}
                      name="pan_image"
                      // value={formData.panImage}
                      onChange={(value) => {
                        uploadImage(value);
                      }}
                    />

                    <div
                      className="addcm_flex_col"
                      id="fileSelect5"
                      onClick={ImageUpload5}
                    >
                      <p>PAN</p>
                      <div className={`myRowCenter `}>
                        <img
                          src={
                            !formData.pan_image
                              ? window.location.origin +
                                "/images/Upload_image.svg"
                              : formData.panImage
                          }
                          alt="aadharfront"
                          className="image_upload_api"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form_submit_button">
              <button onClick={handleSubmit}>Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddCm;
